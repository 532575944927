module KEA.BW.Waermeplanung.Web.Utils.Number {
    export const countDecimals = (value: number): number => {

        if (!value) return 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) return 0;

        const str = value.toString();
        if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
            return str.split("-")[1].length || 0;
        } else if (str.indexOf(".") !== -1) {
            return str.split(".")[1].length || 0;
        }
        return str.split("-")[1].length || 0;
    }
}