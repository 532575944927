module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;
    import TreeViewData = AppKitchen.Api.Models.TreeViewData;
    import Api = AppKitchen.Api;

    export interface TimeSeriesBaseModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class TimeSeriesBaseModel extends AppKitchen.ModelBase<TimeSeriesBaseModelAttributes> {
    }

    export interface TimeSeriesBaseViewOptions extends AppKitchen.ViewBaseOptions {
        importViewApp: AppKitchen.FrameManager.RoutedApp<Apps.ImportZeitreihenAppOptions>;
        headerTitle: string;
        dataRequestEndpoint: string;
        filterRequestEndpoint: string;
        excelFileName: string;
        treeViewTreeId: string;
    }

    export class TimeSeriesBaseView extends AppKitchen.ViewBase<TimeSeriesBaseModel> {
        public options: TimeSeriesBaseViewOptions;
        public requestCall: JQueryXHR;

        protected hasSearch: boolean = false;
        protected isCommune: boolean = true;
        protected selectedCommuneId: string;
        protected treeViewData: TreeViewData[];
        protected showTreeView: boolean = true;
        protected isPublicLogin: boolean = false;

        protected container: {
            title: HTMLElement,
            spreadsheet: JQuery,
            form: HTMLElement,
            actionContainer: HTMLElement,
            search: HTMLElement,
            treeView: HTMLElement,
            treeViewContainer: HTMLElement,
        }

        protected buttons: {
            import: JQuery,
        }
        
        constructor(model: TimeSeriesBaseModel, targetContainer: HTMLElement, options?: TimeSeriesBaseViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options,
                {
                    headerTitle: Strings.TimeSeriesBase_Title,
                    importViewApp: null,
                    dataRequestEndpoint: "ZeitreihenWaermeplanung",
                    filterRequestEndpoint: "ZeitreihenWaermeplanungTimeseriesFilter",
                    excelFileName: "",
                    treeViewTreeId: AppKitchen.GlobalSettings.customConfig.CommuneTree
                }));

            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
            if (applicationInfo) {
                this.isPublicLogin = true;
            }

            this.setTemplate(Web.Templates.TimeSeriesBase);
            this.postRender();
            this.render();
        }

        protected postRender() {}

        public render() {
            this.renderTemplate({});
            this.setContainers();
            this.setButtons();
            this.setEvents();
            this.setHeaderTitle();

            this.model.set({ loading: true });

            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
            this.isCommune = this.isPublicLogin && applicationInfo.IsKommune;
            if (!this.showTreeView || this.isCommune) {
                this.container.treeViewContainer.remove();
                this.selectedCommuneId = applicationInfo?.Commune?.Id;
                this.loadTimeseriesViewList();
            } else {
                // only show the tree view if its not a commune worker
                this.loadTreeView();
            }

            return this;
        }

        protected setContainers(): void {
            this.container = {
                title: this.$el.find(".title")[0],
                spreadsheet: this.$el.find(".spreadsheet-container"),
                form: this.$el.find(".form-container")[0],
                actionContainer: this.$el.find(".action-container")[0],
                search: this.$el.find(".search-field")[0],
                treeView: this.$el.find(".tree-view")[0],
                treeViewContainer: this.$el.find(".tree-view-container")[0],
            }
        }

        protected setButtons(): void {
            this.buttons = {
                import: this.$el.find(".import-button"),
            }
        }

        protected setEvents(): void {
            if (this.isPublicLogin && Services.ApplicationInfoService.instance.getApplicationInfo().IsKommune && this.options.importViewApp) {
                this.buttons.import.click(() => this.openImportModal());
            } else {
                this.buttons.import.remove();
            }
        }

        protected setHeaderTitle(): void {
            this.container.title.innerHTML = this.options.headerTitle;
        }

        protected openImportModal(): void {
            const appOptions: Apps.ImportZeitreihenAppOptions = {
                dataLoaderDocumentName: "",
                dataLoaderDocumentType: "",
                inventoryId: ""
            };
            const windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                buttons: [
                    { name: "undock", action: (window) => this.undockWindow(window) },
                    { name: "close", action: (window) => window.close() }
                ],
                modal: true,
                easyClose: true,
                width: "80%",
                closed: () => {
                    // refocus grid on close (if browser window still focused)
                    //if (document.hasFocus()) this.focusGrid();
                }
            };

            // open window
            this.options.importViewApp.startInWindow(windowOptions);
        }

        protected undockWindow(window: kendo.ui.Window) {
            AppKitchen.BrowserHelper.openInWindow("#/" + this.options.importViewApp.route + "/", true);
            window.close();
        }

        protected loadTimeseriesViewList(): void {
            const endpoint = this.options.filterRequestEndpoint;
            AppKitchen.Data.getData(endpoint, null,
                (response: KEA.BW.Waermeplanung.Domain.TimeseriesViewReference[]) => {
                    this.onTimeseriesViewListLoaded(response);
                },
                (error) => {
                    console.error("Failed to load: " + endpoint);
                    console.error(error);
                    this.model.set({ loading: false });
                });
        }

        protected onTimeseriesViewListLoaded(response: KEA.BW.Waermeplanung.Domain.TimeseriesViewReference[]): void {
            this.model.set({ loading: false });
            this.createForm(response);
        }

        protected createForm(timeseriesViewReferences: KEA.BW.Waermeplanung.Domain.TimeseriesViewReference[]): void {
            const pool: AppKitchen.Controls.Forms.TextPool = {
                values: timeseriesViewReferences,
                textField: "Name",
                valueField: "Id",
                restrict: true,
                search: true
            }

            const fieldModel = new AppKitchen.Controls.Forms.FieldModel(
                "filter",
                AppKitchen.Controls.Forms.FieldType.Text,
                Strings.FilterDropdown_Description,
                {
                    pool: pool,
                    mandatory: true,
                    kendoOptions: {
                        filter: "contains"
                    }
                }
            );

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.TextFieldView(fieldModel, {editable: true});

            const formModel = new AppKitchen.Controls.Forms.FormModel([fieldModel], {});

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.HorizontalTabularFormView(formModel, this.container.form, { editable: true });

            formModel.onAnyValueChange((fieldModel, value) => {
                TimeSeriesBaseRoutingService.setParameters(new TimeSeriesBaseRoutingParameters(value, this.selectedCommuneId));                
                this.loadSpreadsheet(value);
            });

            const parameters = TimeSeriesBaseRoutingService.getParameters();
            formModel.setFieldsData({ filter: parameters != null && parameters.timeseriesViewId ? parameters.timeseriesViewId : timeseriesViewReferences[0].Id });
        }

        protected loadSpreadsheet(timeseriesViewId: string): void {
            AppKitchen.UIHelper.renderLoader(this.container.spreadsheet[0]);

            if (this.requestCall) {
                this.requestCall.abort();
            }
            
            const isCommune = this.isPublicLogin && Services.ApplicationInfoService.instance.getApplicationInfo().IsKommune;
            const request: KEA.BW.Waermeplanung.Domain.TimeseriesRequest = {
                TimeseriesViewId: timeseriesViewId,
                IsCommune: isCommune,
                CommuneId: this.selectedCommuneId
            };

            this.requestCall = this.requestData(request);
        }

        protected requestData(request: KEA.BW.Waermeplanung.Domain.TimeseriesRequest): JQueryXHR {
            const endpoint = this.options.dataRequestEndpoint;
            return AppKitchen.Data.getData(endpoint, request,
                (response: KEA.BW.Waermeplanung.Domain.GridData) => {
                    AppKitchen.UIHelper.removeLoader(this.container.spreadsheet[0]);
                    this.createSpreadsheet(response);
                },
                (requestData, xhr: JQueryXHR, statusText, errorText) => {
                    console.error("Failed to load: " + endpoint);
                    AppKitchen.UIHelper.Notifications.notify(Strings.TimeSeriesBase_Get_Error,
                        "warning",
                        { title: Strings.TimeSeriesBase_Get_Error_Title, closeAfter: 5000 });
                    AppKitchen.UIHelper.removeLoader(this.container.spreadsheet[0]);
                });
        }

        protected createSpreadsheet(gridData: KEA.BW.Waermeplanung.Domain.GridData): kendo.ui.Spreadsheet {
            this.container.spreadsheet.empty();

            const spreadsheet = this.container.spreadsheet.kendoSpreadsheet({
                excel: {
                    // Required to enable saving files in older browsers
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                pdf: {
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                toolbar: false,
                sheetsbar: false,
                sheets: [
                    Utils.Grid.transformSheet(gridData)
                ]
            }).data("kendoSpreadsheet");

            spreadsheet.options.excel.fileName = `${this.options.excelFileName}.xlsx`;
            return spreadsheet;
            //this.removeSpreadsheetActionBar(spreadsheet);
        }

        private loadTreeView(): void {

            const searchBoxModel = new AppKitchen.Controls.SearchBoxModel({ query: "" });
            if (this.hasSearch)
            {
                // ReSharper disable once WrongExpressionStatement
                new AppKitchen.Controls.SearchBoxView(searchBoxModel, this.container.search, {
                    placeholder: AppKitchen.Strings.SearchBox_Search
                });
            }

            let treeModel = new AppKitchen.Controls.TreeView.TreeViewModel<Api.Models.TreeViewData>();

            // ReSharper disable once WrongExpressionStatement
            const treeView = new AppKitchen.Controls.TreeView.TreeView(treeModel, this.container.treeView,
                {
                    //hasCheckboxes: true,
                    //checkParents: true,
                    routingParameter: "items",
                });

            // access the kendo tree control variable to hook up the select function
            const treeControl: kendo.ui.TreeView = (<any>treeView).kendoTreeControl;
            treeControl.bind("change", (e) => this.onTreeSelected(treeControl, e));
            treeControl.bind("select", (e) => this.onTreeSelected(treeControl, e));

            // load the tree data from mesap
            new AppKitchen.Data.Provider.TreeViewProvider().load({ dimension: "Reg", treeId: this.options.treeViewTreeId })
                .then(treeViewData => {
                    this.sortData(treeViewData);
                    treeModel.setTreeViewDomainData(treeViewData, (domainData) => this.convertAll(domainData));
                    // collapse all items
                    treeControl.collapse(".k-item");

                    const parameters = TimeSeriesBaseRoutingService.getParameters();
                    if (!parameters.commune) {
                        const firstNode = this.getFirstNode(treeViewData);
                        this.selectedCommuneId = firstNode.descriptorId;

                    } else {
                        this.selectedCommuneId = parameters.commune;
                    }

                    const item = treeControl.findByText(this.selectedCommuneId);
                    if (item.length > 0) {
                        treeControl.select(item);
                        treeControl.focus();
                    }
                    
                    // load the time series after we got all the communes to filter it (prevents to big data)
                    this.loadTimeseriesViewList();

                    if (this.hasSearch) {
                        searchBoxModel.onChange((query) => {
                            if (treeViewData) {
                                if (query) {
                                    console.log(treeViewData);
                                    const filteredItems = treeViewData.filter((element) => {
                                        // TODO: this filters only the parent nodes, not the child ones
                                        // see: https://docs.telerik.com/kendo-ui/knowledge-base/filter-out-search-results
                                        return element.descriptorName.toLowerCase().includes(query.toLowerCase());
                                    });
                                    treeModel.setTreeViewDomainData(filteredItems,
                                        (domainData) => this.convertAll(domainData));
                                } else {
                                    treeModel.setTreeViewDomainData(treeViewData,
                                        (domainData) => this.convertAll(domainData));
                                }
                            }
                        });
                    }
                });
        }

        private onTreeSelected(treeView: kendo.ui.TreeView, e: any): void {
            if (e.node) {
                const dataItem = treeView.dataItem(e.node);
                if (!(dataItem as any).hasChildren) {
                  
                    treeView.select(e.node);
                    treeView.focus();

                    const communeDescriptorId = (<any>dataItem).data.descriptorId;
                    const parameters = TimeSeriesBaseRoutingService.getParameters();
                    TimeSeriesBaseRoutingService.setParameters(new TimeSeriesBaseRoutingParameters(
                        parameters.timeseriesViewId,
                        communeDescriptorId)
                    );
                    this.selectedCommuneId = communeDescriptorId;
                    //this.model.set({ loading: true });
                    this.loadSpreadsheet(parameters.timeseriesViewId);
                }
            }
        }

        private sortData(treeViewData: TreeViewData[]) {
            // sort 
            treeViewData = treeViewData.sort((a, b) => a.descriptorName.localeCompare(b.descriptorName));
            // sort children
            for (let entry of treeViewData) {
                if (entry.items.length) {
                    this.sortData(entry.items);
                }
            }
        }

        private getFirstNode(treeViewData: TreeViewData[]) {
            for (let entry of treeViewData) {
                if (entry.items.length) {
                    if (entry.items[0].items.length) {
                        return this.getFirstNode(entry.items);
                    } else {
                        return entry.items[0];
                    }
                }
            }
        }
        

        private convertAll(treeViewSubData: Api.Models.TreeViewData[], idPrefix: string = null): AppKitchen.Controls.TreeView.TreeViewItem<Api.Models.TreeViewData>[] {
            return treeViewSubData.AsLinq<Api.Models.TreeViewData>().Select((i) => this.convert(i, idPrefix)).ToArray();
        }

        private convert(treeViewData: Api.Models.TreeViewData, idPrefix: string = null): AppKitchen.Controls.TreeView.TreeViewItem<Api.Models.TreeViewData> {
            const id = idPrefix ? `${idPrefix}_${treeViewData.descriptorId}` : treeViewData.descriptorId;
            return {
                data: treeViewData,
                text: treeViewData.descriptorName,
                imageUrl: treeViewData.dimensionIconData,
                expanded: true,
                items: this.convertAll(treeViewData.items, id),
                id: id,
            }
        }
        
    }
}