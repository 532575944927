/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {

    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;
    import SerializedParameterSet = BW.Waermeplanung.Web.Services.SerializedParameterSet;
    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;

    class RoutingParameters {
        public static readonly commune: string = "kommune";
    }

    export class KommunenSteckbriefRoutingParameters extends ParametersBase {
        constructor(
            public readonly commune: string,
        ) { super() }

        public getParameterSet(): SerializedParameterSet {
            const param: SerializedParameterSet = {};
            param[RoutingParameters.commune] = encodeURI(this.commune);
            return param;
        }
    }

    export class KommunenSteckbriefRoutingService extends RoutingService{

        public static getParameters(): KommunenSteckbriefRoutingParameters {
            const urlQuery = this.readParameters();
            return new KommunenSteckbriefRoutingParameters(
                urlQuery[RoutingParameters.commune],
            );
        }

        public static setParameters(parameters: KommunenSteckbriefRoutingParameters) : void {
            const p = {};
            p[RoutingParameters.commune] = parameters.commune;
            this.writeParameters(p);
        }

        public static getRoute(parameters: KommunenSteckbriefRoutingParameters): string {
            return parameters.buildRoute(Apps.BICO2Routes.kommunenSteckbrief);
        }
    }
}