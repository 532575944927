module KEA.BW.Waermeplanung.Web {

    export module Data {
        export function getDocumentSync(documentName: string, documentType: string) {
            var result = $.ajax({
                dataType: "json",
                type: "GET",
                url: "./api/Document",
                data: { filename: documentName, type: documentType },
                cache: false,
                async: false
            });

            if (result.status === 200) {
                return result.responseJSON;
            }

            AppKitchen.handleError(result, result.statusText, "");

            return undefined;
        }
    }
}