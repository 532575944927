/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {
    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;
    import SerializedParameterSet = BW.Waermeplanung.Web.Services.SerializedParameterSet;
    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;

    export class BICO2RoutingParameters extends ParametersBase {

        constructor(
        ) { super() }
    }

    export class BICO2RoutingService extends RoutingService {

        public static routeTo(parameters: BICO2RoutingParameters): void {
            this.navigate(this.getRoute(parameters, Apps.BICO2Routes.bico2));
        }
    }
}