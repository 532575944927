module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;

    export interface MeldelisteModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class MeldelisteModel extends AppKitchen.ModelBase<MeldelisteModelAttributes> {
    }

    export interface MeldelisteViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class MeldelisteView extends AppKitchen.ViewBase<MeldelisteModel> {
        public options: MeldelisteViewOptions;

        private gridModel: AppKitchen.Controls.Grids.GridModel;
        private gridView: AppKitchen.Controls.Grids.GridView;
        private dataLoader: AppKitchen.Data.EventDataLoader;

        private container: {
            configuration: HTMLElement,
            meldeliste: HTMLElement,
        }

        private buttons: {
        }

        constructor(model: MeldelisteModel, targetContainer: HTMLElement, options?: MeldelisteViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            this.setTemplate(Web.Templates.Meldeliste);

            this.render();
        }

        public render() {
            this.renderTemplate({});
            this.setContainers();
            this.setButtons();
            this.setEvents();

            this.createMeldelisteGrid();

            return this;
        }

        private setContainers(): void {
            this.container = {
                configuration: this.$el.find(".configuration")[0],
                meldeliste: this.$el.find(".meldeliste-grid-container")[0],
            }
        }

        private setButtons(): void {
            this.buttons = {
            }
        }

        private setEvents(): void {
        }

        private createConfigurationForm(): void {
            const berichtsjahrField = new AppKitchen.Controls.Forms.FieldModel('Berichtsjahr',
                AppKitchen.Controls.Forms.FieldType.Year,
                Strings.Field_Berichtsjahr,
                {});

            const labelFieldModel = new AppKitchen.Controls.Forms.FieldModel("Hint",
                AppKitchen.Controls.Forms.FieldType.Text,
                "",
                {
                    //disabled: true,
                    default: Strings.Evaluations_Template_Label,
                    readOnly: true,
                }

            );

            const formModel = new AppKitchen.Controls.Forms.FormModel([berichtsjahrField, labelFieldModel], {});

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.HorizontalTabularFormView(formModel, this.container.configuration,
                {
                    editable: true,
                    showButtons: false,
                });

            formModel.onValueChange('Berichtsjahr', (berichtsjahr) => this.onBerichtsjahrChanged(berichtsjahr));
            formModel.setFieldsData({ Berichtsjahr: this.getBerichtsjahr(), Hint: Strings.Meldeliste_Hint });
        }

        private getBerichtsjahr(): Date {
            const parameters = MeldelisteRoutingService.getParameters();

            if (parameters === null || parameters.berichtsjahr === null) {
                return this.getDefaultYear();
            } else {
                return parameters.berichtsjahr;
            }
        }

        private getDefaultYear(): Date {
            return moment().startOf('year').toDate();
        }

        private onBerichtsjahrChanged(berichtsjahr: Date): void {
            if (!berichtsjahr) {
                return;
            }
            this.updateRoute(berichtsjahr);
            this.updateEventRestrictionsAndReloadData(berichtsjahr);
        }

        private updateRoute(berichtsjahr: Date): void {
            MeldelisteRoutingService.setParameters(new MeldelisteRoutingParameters(berichtsjahr));
        }

        private updateEventRestrictionsAndReloadData(berichtsjahr: Date): void {
            const config = this.dataLoader.getConfig();

            config.EventItemRestrictions[0].Value = berichtsjahr;

            this.dataLoader.setConfig(config, this);

            this.dataLoader.loadData();
        }

        private createMeldelisteGrid(): void {
            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
            const config: AppKitchen.Data.GridConfig = JSON.parse(Data.getDocumentSync("Meldeliste", "Meldeliste"));
            if (applicationInfo.IsKommune) {
                config.EventItemRestrictions.push(
                    {
                        InventoryId: "Meldeliste",
                        ItemId: "NavObject_Reg",
                        Operation: "AND",
                        Value: applicationInfo.Commune.Id
                    }
                );
            } else if (applicationInfo.CommuneList.length < 50) {
                applicationInfo.CommuneList.forEach(c => {
                    config.EventItemRestrictions.push({
                        InventoryId: "Meldeliste",
                        ItemId: "NavObject_Reg",
                        Operation: "OR",
                        Value: c.Id
                    });
                });
            }
            this.dataLoader = new MeldelisteDataLoader(config, () => {
                this.gridModel = new MeldelisteGridModel(this.dataLoader, {});
                this.gridView = new AppKitchen.Controls.Grids.GridView(this.gridModel, this.container.meldeliste,
                    {
                        fillHeight: true,
                        initialSort: { field: 'Meldeliste_NavObject_Reg', dir: 'asc' },
                        templates: [
                            { field: 'Meldeliste_NavObject_Reg', template: (model) => this.templateCommune(model) },
                            { field: 'Wärmepläne_Pruefstatus', template: (model) => Prüfstatus.translateStatus(model, 'Wärmepläne_Pruefstatus') },
                            { field: 'Meldeliste_Pruefstatus', template: (model) => Prüfstatus.translateStatus(model, 'Meldeliste_Pruefstatus') }
                        ],
                        dblclick: (rowData) => {
                            const medelisteId = rowData['Meldeliste_Id'];
                            DeteilansichtMeldungenRoutingService.navigate(DeteilansichtMeldungenRoutingService.getRoute(
                                new DeteilansichtMeldungenRoutingParameters(medelisteId))
                            );
                        },
                        filterable: true
                    });

                this.createConfigurationForm();

                AppKitchen.UIHelper.updateFullHeightGrids(this.el);
            });
        }

        private templateCommune(model): string {
            const medelisteId = model['Meldeliste_Id'];
            const commune = model['Meldeliste_NavObject_Reg_Name'];
            const route = DeteilansichtMeldungenRoutingService.getRoute(new DeteilansichtMeldungenRoutingParameters(medelisteId));

            return `<a href='${route}'>${commune}</a>`;
        }
    }

    export class MeldelisteDataLoader extends AppKitchen.Data.EventDataLoader {

        parseDataInternal(data: AppKitchen.Data.DataEntries[]): AppKitchen.Data.DataEntries[] {
            const dict: { [id: string]: any } = {}
            data.forEach((d: any ) => {
                if (!dict[d.Meldeliste_Id]) {
                    const newEntry = { ...d };
                    newEntry['Anzahl_Wärmepläne'] = d.Wärmepläne_Prüfstatus !== "" ? 1 : 0;
                    dict[d.Meldeliste_Id] = newEntry;
                } else {
                    const entry = dict[d.Meldeliste_Id];
                    entry['Wärmepläne_Pruefstatus'] = Prüfstatus.compareStatus(entry.Wärmepläne_Pruefstatus, d.Wärmepläne_Pruefstatus) === -1 ? entry.Wärmepläne_Pruefstatus : d.Wärmepläne_Pruefstatus
                    entry['Anzahl_Wärmepläne']++;                    
                }
            });

            return Object.keys(dict).map(key => dict[key]);
        }

        setData(data: AppKitchen.Data.DataEntries[], sender: object): void {
            super.setData(this.parseDataInternal(data), sender);
        }
    }

    export class Prüfstatus {
        public static readonly PruefungOffen: string = "Prüfung offen";
        public static readonly Freigegeben: string = "freigegeben";
        public static readonly MaengelFestgestellt: string = "Mängel festgestellt";
        public static readonly Zurueckgezogen: string = "zurückgezogen";

        public static compareStatus(a: string, b: string): number {
            if (a === b) {
                return 0;
            }
            if (a === Prüfstatus.MaengelFestgestellt) {
                return -1;
            }
            if (b === Prüfstatus.MaengelFestgestellt) {
                return 1;
            }
            if (a === Prüfstatus.PruefungOffen) {
                return -1;
            }
            if (b === Prüfstatus.PruefungOffen) {
                return 1;
            }
            if (a === Prüfstatus.Freigegeben) {
                return -1;
            }
            if (b === Prüfstatus.Freigegeben) {
                return 1;
            }
            if (a === Prüfstatus.Zurueckgezogen) {
                return -1;
            }
            if (b === Prüfstatus.Zurueckgezogen) {
                return 1;
            }
            return 0;
        }

        public static translateStatus(model, key: string): string {
            const status = model[key];
            let clazz = "";
            if (status === Prüfstatus.PruefungOffen) {
                clazz = 'offen';
            } else if (status === Prüfstatus.Freigegeben) {
                clazz = 'freigegben';
            } else if (status === Prüfstatus.MaengelFestgestellt) {
                clazz = 'maengel';
            } else if (status === Prüfstatus.Zurueckgezogen) {
                clazz = 'zurueckgezogen';
            }
            return `<span class='status ${clazz}'>${status}</span>`;;
        }
    }

    export class MeldelisteGridModel extends AppKitchen.Controls.Grids.EventGridModel {

        constructor(dataLoader: AppKitchen.Data.EventDataLoader, options?: AppKitchen.Controls.Grids.EventGridOptions) {
            super(dataLoader, AppKitchen.OptionsHelper.merge(options, <AppKitchen.Controls.Grids.EventGridOptions>{
                getColumnHeader: MeldelisteGridModel.getColumnHeader
            }));

            var schema = this.get().schema;
            var columns = this.addVirtualColumns();

            schema.push({
                field: "Anzahl_Wärmepläne",
                type: "number",
                validation: {
                    "required": false
                },
                editable: true
            });

            this.set({
                columns: columns,
                schema: schema
            });
        }

        private static getColumnHeader(inventoryId: string, itemId: string, eventItem: AppKitchen.Api.Models.EventItemInfo): string {
            // special case for pruefstatus because the field exists twice (one in meldeliste, one in waermeplaene)
            return Strings["Field_" + inventoryId + "_" + itemId] ||Strings["Field_Meldeliste_" + eventItem.Name] || eventItem.Name;
        }

        private addVirtualColumns(): kendo.ui.GridColumn[] {
            const columns: kendo.ui.GridColumn[] = [];
            this.get().columns
                .forEach(c => columns.push(c));

            const waermeplaeneAnazhl = {
                field: "Anzahl_Wärmepläne",
                title: "Anzahl Wärmepläne",
                width: "150px"
            }

            columns.splice(2, 0, waermeplaeneAnazhl);
            //columns.push(waermeplaeneAnazhl);

            return columns;
        }

     }
}