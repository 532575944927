/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {

    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;
    import SerializedParameterSet = BW.Waermeplanung.Web.Services.SerializedParameterSet;
    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;

    class RoutingParameters {
        public static readonly Berichtsjahr: string = "berichtsjahr";
    }

    export class MeldelisteRoutingParameters extends ParametersBase {
        constructor(
            public readonly berichtsjahr?: Date,
        ) { super() }

        public getParameterSet(): SerializedParameterSet {
            const param: SerializedParameterSet = {};
            param[RoutingParameters.Berichtsjahr] = moment(this.berichtsjahr).format('yyyy');
            return param;
        }
    }

    export class MeldelisteRoutingService extends RoutingService{

        public static getParameters(): MeldelisteRoutingParameters {
            const urlQuery = this.readParameters();
            return new MeldelisteRoutingParameters(
                urlQuery[RoutingParameters.Berichtsjahr] ? moment([urlQuery[RoutingParameters.Berichtsjahr]]).toDate() : null,
            );
        }

        public static setParameters(parameters: MeldelisteRoutingParameters) : void {
            const p = {};
            if (parameters.berichtsjahr) {
                p[RoutingParameters.Berichtsjahr] = parameters.berichtsjahr.getFullYear();
            }
            this.writeParameters(p);
        }

        public static getRoute(parameters: MeldelisteRoutingParameters): string {
            return parameters.buildRoute(Apps.Routes.Meldeliste);
        }
    }
}