module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;

    export interface StatistischeGrunddatenModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class StatistischeGrunddatenModel extends AppKitchen.ModelBase<StatistischeGrunddatenModelAttributes> {
    }

    export interface StatistischeGrunddatenViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class StatistischeGrunddatenView extends AppKitchen.ViewBase<StatistischeGrunddatenModel> {
        public options: StatistischeGrunddatenViewOptions;
        public requestCall: JQueryXHR;
        private commune: string = "";

        private container: {
            grid: JQuery,
            title: JQuery
        }

        private buttons: {
            exportGrid: JQuery;
            downloadTemplate: JQuery;
        }
     
        constructor(model: StatistischeGrunddatenModel, targetContainer: HTMLElement, options?: StatistischeGrunddatenViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            this.setTemplate(Web.Templates.StatistischeGrunddaten);

            this.render();
            this.checkDownload();
        }

        public render()  {
            this.renderTemplate({});
            this.setContainers();

            const parameters = StatistischeGrunddatenRoutingService.getParameters();
            this.setTitle(parameters.commune);
            this.loadSpreadsheet(parameters.commune);
            this.commune = parameters.commune;
            return this;
        }

        private checkDownload(): void {
            const parameters = NutzungsdatenRoutingService.getParameters();
            KEA.BW.Waermeplanung.Web.Utils.File.checkDownload(parameters);
        }

        private setContainers(): void {
            this.container = {
                grid: this.$el.find<HTMLElement>(".grid-container"),
                title: this.$el.find<HTMLElement>(".title"),
            }
            this.buttons = {
                exportGrid: this.$el.find(".a-button-export"),
                downloadTemplate: this.$el.find(".a-button-template")
            }

            this.buttons.downloadTemplate.click(() => {
                NutzungsdatenRoutingService.routeTo(
                    new NutzungsdatenRoutingParameters(
                        this.commune,
                        Apps.BICO2Routes.statistischeGrunddaten,
                        AppKitchen.GlobalSettings.customConfig.TemplateStatistischeGrunddatenPath,
                        Strings.Nutzungsdaten_Typ_Template_Statistische_Grunddaten)
                );
            });
        }

        private setTitle(title: string): void {
            this.container.title[0].innerHTML = Utils.StringUtils.format(Strings.StatistischeGrunddaten_Title, title);
        }

        private loadSpreadsheet(commune: string): void {
            // ReSharper disable once WrongExpressionStatement
            AppKitchen.UIHelper.renderLoader(this.container.grid[0]);

            if (this.requestCall) {
                this.requestCall.abort();
            }

            const request: KEA.BW.Waermeplanung.Domain.SampleRequest = { Id: commune};
            this.requestCall = AppKitchen.Data.getData("ZeitreihenStatistischeGrunddaten",
                request,
                (response: KEA.BW.Waermeplanung.Domain.GridData) => {
                    AppKitchen.UIHelper.removeLoader(this.container.grid[0]);
                    this.createSpreadsheet(response);
                },
                () => {
                    console.log("Failed to load: 'ZeitreihenStatistischeGrunddaten'");
                    AppKitchen.UIHelper.removeLoader(this.container.grid[0]);
                });
        }

        private createSpreadsheet(gridData: KEA.BW.Waermeplanung.Domain.GridData): void {
            this.container.grid.empty();

            const spreadsheet = this.container.grid.kendoSpreadsheet({
                excel: {
                    // Required to enable saving files in older browsers
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                pdf: {
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                toolbar: false,
                sheetsbar: false,
                sheets: [
                    Utils.Grid.transformSheet(gridData)
                ],
            }).data("kendoSpreadsheet");

            // desired format: TTMMJJJJhhmmss
            spreadsheet.options.excel.fileName = "BICO2-Daten_" + this.commune + ".xlsx";
            this.buttons.exportGrid.click(() => spreadsheet.saveAsExcel());

            //this.removeSpreadsheetActionBar(spreadsheet);
        }
    }

}