module KEA.BW.Waermeplanung.Web.FunicularSwitch {

    export abstract class Result<T> {

        public static ok<T1>(value: T1): Result<T1> {
            return new Ok(value);
        }

        public static error<T1>(error: string): Result<T1> {
            return new Error(error);
        }

        private readonly _tag;

        constructor(_tag) {
            this._tag = _tag;
        }

        public isOk(): boolean {
            return this._tag === Ok.Tag;
        }

        public isError(): boolean {
            return this._tag === Error.Tag;
        }

        public map<T1>(ok: (value: T) => T1, error: () => T1): T1 {
            return this.isOk() ? ok((<any>this).value) : error();
        }

        public match(ok: (value: T) => void, error: (error: string) => void): void {
            this.isOk() ? ok((<any>this).value) : error((<any>this).error);
        }
    }

    class Ok<T> extends Result<T> {
        public static Tag = 'Ok';
        public readonly value: T;

        constructor(value: T) {
            super(Ok.Tag);
            this.value = value;
        }
    }

    class Error<T> extends Result<T> {
        public static Tag = 'Error';
        public readonly error: string;

        constructor(error: string) {
            super(Error.Tag);
            this.error = error;
        }
    }
}