namespace KEA.BW.Waermeplanung.Web {

    export function startWaermeplanung(appContainer: HTMLElement) {
        // ReSharper disable once WrongExpressionStatement
        new Apps.WaermeplanungMainApp(appContainer, { requiresLogin: true });
    }

    export function startEnergiedaten(appContainer: HTMLElement) {
        // ReSharper disable once WrongExpressionStatement
        new Apps.EnergiedatenMainApp(appContainer, { requiresLogin: true });
    }

    export function startBICO2(appContainer: HTMLElement) {
        // ReSharper disable once WrongExpressionStatement        
        new Apps.BICO2MainApp(appContainer, { requiresLogin: false });
    }

    export function startTechnologiedaten(appContainer: HTMLElement) {
        // ReSharper disable once WrongExpressionStatement        
        new Apps.TechnologiedatenMainApp(appContainer, { requiresLogin: false });
    }
}