module KEA.BW.Waermeplanung.Web.Apps {

    export interface BICO2AppOptions extends AppKitchen.AppBaseOptions {
        importViewApp: AppKitchen.FrameManager.RoutedApp<Apps.BICO2AppOptions>;
    }

    export class BICO2App extends AppKitchen.AppBase<BICO2AppOptions> {

        start() {
            document.title = this.options.title = Strings.BICO2_PageTitle;
            const model = new Controls.BICO2Model();

            // ReSharper disable once WrongExpressionStatement
            new Controls.BICO2View(model, this.el,
                {
                });
        }

        dispose() {
        }
    }
}