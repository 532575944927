module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;

    export interface KommunenSteckbriefModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class KommunenSteckbriefModel extends AppKitchen.ModelBase<KommunenSteckbriefModelAttributes> {
    }

    export interface KommunenSteckbriefViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class KommunenSteckbriefView extends AppKitchen.ViewBase<KommunenSteckbriefModel> {
        public options: KommunenSteckbriefViewOptions;
        public requestCall: JQueryXHR;
        private commune: string = "";

        private container: {
            grid: JQuery,
            title: JQuery
        }

        private buttons: {
            exportGrid: JQuery;
            downloadTemplate: JQuery;
        }
     
        constructor(model: KommunenSteckbriefModel, targetContainer: HTMLElement, options?: KommunenSteckbriefViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            this.setTemplate(Web.Templates.KommunenSteckbrief);

            this.render();
            this.checkDownload();
        }

        public render() {
            this.renderTemplate({});
            this.setContainers();

            const parameters = KommunenSteckbriefRoutingService.getParameters();
            this.setTitle(parameters.commune);
            this.loadSpreadsheet(parameters.commune);
            this.commune = parameters.commune;
            return this;
        }

        private checkDownload(): void {
            const parameters = NutzungsdatenRoutingService.getParameters();
            KEA.BW.Waermeplanung.Web.Utils.File.checkDownload(parameters);            
        }

        private setContainers(): void {
            this.container = {
                grid: this.$el.find<HTMLElement>(".grid-container"),
                title: this.$el.find<HTMLElement>(".title"),
            }
            this.buttons = {
                exportGrid: this.$el.find(".a-button-export"),
                downloadTemplate: this.$el.find(".a-button-template")
            }

            this.buttons.downloadTemplate.click(() => {
                NutzungsdatenRoutingService.routeTo(
                    new NutzungsdatenRoutingParameters(
                        this.commune,
                        Apps.BICO2Routes.kommunenSteckbrief,
                        AppKitchen.GlobalSettings.customConfig.TemplateKommunensteckbriefPath,
                        Strings.Nutzungsdaten_Typ_Template_Kommunensteckbrief)
                );
            });
        }

        private setTitle(title: string): void {
            this.container.title[0].innerHTML = Utils.StringUtils.format(Strings.KommunenSteckbrief_Title, title);
        }

        private loadSpreadsheet(commune: string): void {
            // ReSharper disable once WrongExpressionStatement
            AppKitchen.UIHelper.renderLoader(this.container.grid[0]);

            if (this.requestCall) {
                this.requestCall.abort();
            }

            const request: KEA.BW.Waermeplanung.Domain.SampleRequest = { Id: commune};
            this.requestCall = AppKitchen.Data.getData("ZeitreihenKommunenSteckbrief",
                request,
                (response: KEA.BW.Waermeplanung.Domain.GridData) => {
                    AppKitchen.UIHelper.removeLoader(this.container.grid[0]);
                    this.createSpreadsheet(response);
                },
                () => {
                    console.log("Failed to load: 'ZeitreihenKommunenSteckbrief'");
                    AppKitchen.UIHelper.removeLoader(this.container.grid[0]);
                });
        }

        private createSpreadsheet(gridData: KEA.BW.Waermeplanung.Domain.GridData): void {
            this.container.grid.empty();

            const spreadsheet = this.container.grid.kendoSpreadsheet({
                excel: {
                    // Required to enable saving files in older browsers
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                pdf: {
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                toolbar: false,
                sheetsbar: false,
                sheets: [
                    Utils.Grid.transformSheet(gridData)
                ],
            }).data("kendoSpreadsheet");

            // desired format: TTMMJJJJhhmmss
            spreadsheet.options.excel.fileName = "Daten-Kommunensteckbrief_" + this.commune + ".xlsx";
            this.buttons.exportGrid.click(() => spreadsheet.saveAsExcel());

            //this.removeSpreadsheetActionBar(spreadsheet);
        }
    }

}