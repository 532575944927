module KEA.BW.Waermeplanung.Web.Controls {

    export interface UploadWaermeplanModelAttributes extends AppKitchen.ModelBaseAttributes {
        file?: File;
        fileName?: string;
        fileSize?: string;
        dokumententyp?: string;
        documentId: number;
        label?: string;
        meldelisteId?: string;
        prüfstatus?: string;
        uploadProgress?: number;
        isAlreadyUploaded?: boolean;
    }

    export class UploadWaermeplanModel extends AppKitchen.ModelBase<UploadWaermeplanModelAttributes> {

        public dataLoader: AppKitchen.Data.EventDataLoader;
        private parameters: DeteilansichtMeldungenRoutingParameters;
        private userId: string;

        public successUploadCallback: () => void;
        public failUploadCallback: () => void;

        constructor(dataLoader: AppKitchen.Data.EventDataLoader, attributes: UploadWaermeplanModelAttributes) {
            super(attributes);

            this.dataLoader = dataLoader;

            this.setParameters();
            this.setEvents();
        }

        private setParameters(): void {
            this.parameters = DeteilansichtMeldungenRoutingService.getParameters();
            if (this.parameters === null || this.parameters.meldelisteId === null) {
                console.error(`No parameters where set!`);
            }

            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
            this.userId = applicationInfo.UserId;
        }

        public updateFile(file: File): void {
            this.set(AppKitchen.OptionsHelper.merge({
                file: file,
                fileName: Utils.File.getFileName(file),
            }, this.attributes));
        }

        public reset(): void {
            this.set(AppKitchen.OptionsHelper.merge({
                file: null,
                fileName: null
            }, this.attributes));
        }

        private setEvents(): void {
            this.dataLoader.onChangeData().subscribe(() => {
                const data = this.dataLoader.getData();
                const textPool = this.dataLoader.getEventFieldInfo('Wärmepläne_Dokumententyp').TextPool;
                // the returned array isn't sorted like in mesap so we can't just access the array index

                const currentDocument = textPool.find(element => element.SortNr === this.get().documentId);
                if (currentDocument) {
                    this.set({
                        dokumententyp: currentDocument.Id,
                        label: currentDocument.Text
                    });
                } else {
                    console.error("Error: Mesap entry not found for SortNr: " + this.get().documentId);
                }

                data.forEach(d => {
                    if (d['Wärmepläne_Pruefstatus'] !== 'zurückgezogen' &&
                        d['Wärmepläne_Dokumententyp'] === this.get().dokumententyp) {

                        const label = textPool.find(element => element.Id === d['Wärmepläne_Dokumententyp']);
                        this.set({
                            fileName: this.getDocumentNameFromName(d['Wärmepläne_Dokument']),
                            fileSize: this.getDocumentSizeFromName(d['Wärmepläne_Dokument']),
                            dokumententyp: d['Wärmepläne_Dokumententyp'],
                            label: label.Text,
                            meldelisteId: d['Meldeliste_Id'],
                            prüfstatus: d['Wärmepläne_Pruefstatus'],
                            isAlreadyUploaded: true
                        });
                    }
                });
            });
        }

        private getDocumentNameFromName(documentName: string): string {
            if (!documentName) {
                return '';
            }
            const pos = documentName.lastIndexOf('(');
            return documentName.substr(0, pos).trim();
        }

        private getDocumentSizeFromName(documentName: string): number {
            if (!documentName) {
                return 0;
            }
            const pos = documentName.lastIndexOf('(');
            return parseInt(documentName.substr(pos + 1, documentName.length - pos - 4).replace('.', '').trim());
        }

        public uploadFile(): void {
            this.sendFile();
        }

        public sendFile(finished?: () => void): void {
            if (!this.get().fileName || !this.get().dokumententyp || this.get().isAlreadyUploaded) {
                if(finished) finished();
                return;
            }

            const apiUrl = "./api";
            $.ajax({
                dataType: "json",
                url: `${apiUrl}/WaermeplanFileUpload` +
                    `?fileName=${encodeURI(this.get().fileName)}` +
                    `&dokumententyp=${encodeURI(this.get().dokumententyp)}` +
                    `&meldelistenId=${escape(this.parameters.meldelisteId)}`,
                type: 'POST',
                xhr: () => {
                    const myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', e => this.uploadProgressHandling(e), false);
                    }
                    return myXhr;
                },
                success: (result) => {
                    if (result) {
                        this.dataLoader.loadData();
                        this.set({ isAlreadyUploaded: true});
                        if (this.successUploadCallback) {
                            this.successUploadCallback();
                        }
                    } else {
                        if (this.failUploadCallback) {
                            this.failUploadCallback();
                        }
                    }
                    if (finished) finished();
                },
                error: (error) => {
                    console.error(error);
                    if (this.failUploadCallback) {
                        this.failUploadCallback();
                    }
                    if (finished) finished();
                },
                data: this.get().file,
                cache: false,
                contentType: false,
                processData: false
            });
        }

        private uploadProgressHandling(e: ProgressEvent<XMLHttpRequestEventTarget>): void {
            if (e.lengthComputable) {
                const s = e.loaded / e.total * 100;
                this.set({
                    uploadProgress: s
                });
            }
        }
    }

    export interface UploadWaermeplanViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class UploadWaermeplanView extends AppKitchen.ViewBase<UploadWaermeplanModel> {
        public options: UploadWaermeplanViewOptions;
        private inputId: string;

        private acceptedFileTypes: string[] = ["pdf"];

        private infoNotification: AppKitchen.Controls.Popups.Notification;
        //private uploadFileTooltip: kendo.ui.Tooltip;
        private uploadButtonEnabled: boolean;

        private container: {
            filePickerContent: HTMLElement,
        }

        private buttons: {
            uploadFile: JQuery,
        };

        private inputs: {
            file: JQuery,
        };

        constructor(model: UploadWaermeplanModel, targetContainer: HTMLElement, options?: UploadWaermeplanViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            this.inputId = Utils.Guid.newGuid();
            this.setTemplate(Web.Templates.UploadWaermeplan);

            this.render();

            this.model.bind('change', () => this.render());
        }

        public render() {
            this.renderTemplate({
                title: this.model.get().label,
                accept: this.acceptedFileTypes.map(s => `.${s}`).join(','),
                inputId: this.inputId
            });
            this.setContainers();
            this.setInputs();
            this.setButtons();
            this.setEvents();
            this.init();
            //this.createImportList();
            return this;
        }

        private init(): void {
            this.onModelChanged();
        }

        private setContainers(): void {
            this.container = {
                filePickerContent: this.$el.find(".upload-file-picker-content")[0]
            }
        }

        private setButtons(): void {
            this.buttons = {
                uploadFile: this.$el.find(".upload-file-button")
            }
        }

        private setInputs(): void {
            this.inputs = {
                file: this.$el.find(".upload-file-picker"),
            };
        }

        private setEvents(): void {
            this.inputs.file.change(e => this.onTradesFileChanged(e));

            this.model.bind('change', () => this.onModelChanged());

            this.buttons.uploadFile.click(() => {
                if (this.uploadButtonEnabled) {
                    this.uploadFile();
                }
            });

            this.$el.find(".upload-file-picker-label")
                .on("dragenter", (e) => this.onDragEnter(e))
                .on("dragover", (e) => this.onDragOver(e))
                .on("dragleave", (e) => this.onDragLeave(e))
                .on("drop", (e) => this.onDrop(e));

            this.model.successUploadCallback = this.successUpload.bind(this);
            this.model.failUploadCallback = this.uploadFailed.bind(this);

            this.model.bind('change', () => this.setProgress(this.model.get().uploadProgress));
        }

        private onDragEnter(event): void {
            event.preventDefault();

            this.$el.find(".upload-file-picker-container").css('background-color', '#deebff');
        }

        private onDragOver(event): void {
            event.preventDefault();
        }

        private onDragLeave(event): void {
            event.preventDefault();

            this.$el.find(".upload-file-picker-container").css('background-color', '');
        }

        private onDrop(event): void {
            event.preventDefault();

            this.$el.find(".upload-file-picker-container").css('background-color', '');

            const file = event.originalEvent.dataTransfer.files[0];
            this.selectFile(file);
        }

        private onTradesFileChanged(e: JQuery.ChangeEvent): void {
            if (e.target.files.length === 0) {
                this.model.reset();
                return;
            }

            const file = e.target.files[0];
            this.selectFile(file);

            const newInput = this.$el.find(e.target).clone(true);
            newInput.val("");
            this.$el.find(e.target).replaceWith(newInput);
        }

        private selectFile(file: File): void {
            if (file && Utils.File.acceptFile(file, this.acceptedFileTypes)) {
                this.model.updateFile(file);
            } else {
                this.notifyWrongFileFormat(file.name);
                this.model.reset();
            }
        }

        private onModelChanged(): void {
            if (this.model.get().isAlreadyUploaded) {
                AppKitchen.UIHelper.renderTemplateTo(this.container.filePickerContent,
                    Templates.FileItem,
                    {
                        fileName: this.model.get().fileName,
                        fileIcon: this.getFileTypeIcon(Utils.File.getFileExtensionFromName(this.model.get().fileName)),
                        fileSize: Utils.File.fileSizeToString(this.model.get().fileSize, true)
                    });

                this.$el.find(".upload-file-picker-container").css('pointer-events', 'none');

                this.enableUploadFileButton(false);
            } else if (this.model.get().fileName) {
                AppKitchen.UIHelper.renderTemplateTo(this.container.filePickerContent,
                    Templates.FileItem,
                    {
                        fileName: this.model.get().fileName,
                        fileIcon: this.getFileTypeIcon(Utils.File.getFileExtension(this.model.get().file)),
                        fileSize: Utils.File.fileSizeToString(this.model.get().file.size, true)
                    });

                this.$el.find(".remove-button").addClass('active');
                this.$el.find(".remove-button").click((e) => this.removeFile(e));

                this.enableUploadFileButton(true);
            } else {
                AppKitchen.UIHelper.renderTemplateTo(this.container.filePickerContent,
                    Templates.DataFileItemEmpty);

                this.$el.find(".remove-button").removeClass('active');
                this.enableUploadFileButton(false);
            }
        }

        private getFileTypeIcon(fileExtenstion: string): string {
            if (['xlsx', 'xlsm', 'xls', 'csv'].some(s => s === fileExtenstion)) {
                return 'icon-file-excel-v1-32px';
            }
            if (['pdf'].some(s => s === fileExtenstion)) {
                return 'icon-file-pdf-v1-32px';
            }
            if (['xml'].some(s => s === fileExtenstion)) {
                return 'icon-file-xml-v1-32px';
            }
            if (['zip'].some(s => s === fileExtenstion)) {
                return 'icon-file-zip-32px';
            }
            if (['doc', 'docx'].some(s => s === fileExtenstion)) {
                return 'icon-file-word-v1-32px';
            }
            if (['ppt', 'pptx'].some(s => s === fileExtenstion)) {
                return 'icon-file-powerpoint-v1-32px-2';
            }
            return 'icon-file-xml-v1-32px';
        }

        private enableUploadFileButton(isEnable: boolean): void {
            //if (this.uploadFileTooltip) {
            //    this.uploadFileTooltip.destroy();
            //    this.uploadFileTooltip = null;
            //}

            this.uploadButtonEnabled = isEnable;
            if (isEnable) {
                this.buttons.uploadFile.removeClass('disabled');
            } else {
                this.buttons.uploadFile.addClass('disabled');

                //this.uploadFileTooltip = this.buttons.uploadFile.kendoTooltip(
                //    {
                //        content: () => Strings.ImportZeitreihenWaermeplanung_Upload_Button_Tooltip_NoFile,
                //        position: "bottom"
                //    }).data("kendoTooltip");
            }
        }

        private removeFile(event): void {
            event.preventDefault();
            this.model.reset();
        }

        private uploadFile(): void {
            if (!this.model.get().file) {
                return;
            }

            this.notifyProceedUpload();

            this.model.uploadFile();
        }

        private uploadFailed(): void {
            console.log("Uploaded failed.");
            this.setProgress(0);
            this.notifyFailUpload();
        }

        private setProgress(percent: number): void {
            this.$el.find(".progressbar-progress").width(percent + "%");
        }

        private successUpload(): void {
            this.notifySucceedUpload();

            this.$el.find(".upload-file-picker-container").css('pointer-events', 'none');

            this.enableUploadFileButton(false);
        }

        private notifyProceedUpload(): void {
            this.infoNotification = AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_Progress_Content, this.model.get().fileName),
                'info',
                {
                    closeAfter: 30000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_Progress_Title
                });
        }

        private hideProcessNotification(): void {
            if (this.infoNotification) {
                this.infoNotification.close();
            }
        }

        private notifySucceedUpload(): void {
            this.hideProcessNotification();
            AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_Success_Content, this.model.get().fileName),
                'success',
                {
                    closeAfter: 10000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_Success_Title
                });
        }

        private notifyFailUpload(): void {
            this.hideProcessNotification();
            AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_Fail_Content, this.model.get().fileName),
                'warning',
                {
                    closeAfter: 10000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_Fail_Title
                });
        }

        private notifyNoCommuneSet(): void {
            AppKitchen.UIHelper.Notifications.notify(
                Strings.ImportZeitreihenWaermeplanung_Notification_NoCommune_Content,
                'info',
                {
                    closeAfter: 10000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_NoCommune_Title
                });
        }

        private notifyWrongFileFormat(fileName: string): void {
            AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_WrongFile_Content, fileName, this.acceptedFileTypes.map(s => `.${s}`).join(', ')),
                "warning",
                {
                    closeAfter: 30000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_WrongFile_Title,
                });
        }
    }
}