/// <reference path="../TimeSeriesBase/TimeSeriesBase.ts"/>
module KEA.BW.Waermeplanung.Web.Apps {

    export interface EvaluationsAppOptions extends TimeSeriesBaseAppOptions {
        templateFolder: string;
        dropDownLabel: string;
        downloadButtonLabel?: string;
    }

    export class EvaluationsApp extends TimeSeriesBaseApp<EvaluationsAppOptions> {
        start() {
            document.title = this.options.title = Strings.Evaluations_PageTitle;
            const model = new Controls.EvaluationsModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.EvaluationsView(model, this.el,
                {
                    headerTitle: this.options.headerTitle,
                    importViewApp: this.options.importViewApp,
                    dataRequestEndpoint: this.options.dataRequestEndpoint,
                    filterRequestEndpoint: this.options.filterRequestEndpoint,
                    excelFileName: "",
                    templateFolder: this.options.templateFolder,
                    dropdownLabel: this.options.dropDownLabel,
                    downloadButtonLabel: this.options.downloadButtonLabel,
                    treeViewTreeId: this.options.treeViewTreeId
                });
        }

        dispose() {
        }
    }
}