/// <reference path="../../Controls/Nutzungsdaten/NutzungsdatenRoutingService.ts"/>
/// <reference path="KEABaseApp.ts"/>

namespace KEA.BW.Waermeplanung.Web.Apps {
    import NutzungsdatenRoutingService = BW.Waermeplanung.Web.Controls.NutzungsdatenRoutingService;
    import NutzungsdatenRoutingParameters = BW.Waermeplanung.Web.Controls.NutzungsdatenRoutingParameters;

    export class TechnologiedatenRoutes {
        public static readonly Technologiedaten: string = "";
        public static readonly NutzungsDaten: string = "nutzer-infos";
    }

    export class TechnologiedatenMainApp extends KEABaseApp {

        // this is a public site, there is no application info
        start() {
            Utils.Localization.TranslateSpreadsheetToGerman();
            this.startApp();

            // always route to nutzungsdaten before showing anything
            NutzungsdatenRoutingService.routeTo(new NutzungsdatenRoutingParameters("",
                "",
                "",
                Strings.Nutzungsdaten_Typ_Technologiedaten));
        }


        getRoutedAppsAndMenu(): [apps: AppKitchen.FrameManager.RoutedApp<any>[],
            menu: AppKitchen.FrameManager.MenuItem] {

            const nutzungsDaten = new AppKitchen.FrameManager.RoutedApp<NutzungsdatenAppOptions>(Apps.NutzungsdatenApp, TechnologiedatenRoutes.NutzungsDaten);
            const technologiedaten = new AppKitchen.FrameManager.RoutedApp<EvaluationsAppOptions>(
                Apps.EvaluationsApp, TechnologiedatenRoutes.Technologiedaten,
                {
                    headerTitle: Strings.Technologiedaten_PageTitle,
                    importViewApp: null,
                    dataRequestEndpoint: "ZeitreihenTechnologiedaten",
                    filterRequestEndpoint: "ZeitreihenTechnologiedatenTimeseriesFilter",
                    templateFolder: AppKitchen.GlobalSettings.customConfig.TemplateTechnologieDaten,
                    dropDownLabel: Strings.Technologiedaten_DropdownLabel,
                    downloadButtonLabel: Strings.Technologiedaten_Template_Download,
                    treeViewTreeId: AppKitchen.GlobalSettings.customConfig.CommuneTree
                });

            const routedApps = [
                technologiedaten,
                nutzungsDaten
            ];

            const menu = new AppKitchen.FrameManager.MenuItem(Strings.Menu_PageTitle, null,
                [
                    new AppKitchen.FrameManager.MenuItem(Strings.Menu_Technologiedaten, { route: TechnologiedatenRoutes.Technologiedaten, highlightOnNavigate: true }),
                ]);

            return [routedApps, menu];
        }

        getTemplate(): string {
            return Templates.TechnologiedatenMainApp;
        }       
    }
}