module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;
    import Api = AppKitchen.Api;
    import FormSubmitOptions = AppKitchen.Controls.Forms.FormSubmitOptions;
    import UiHelper = AppKitchen.UIHelper;

    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;
    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;

    export interface NutzungsdatenModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class NutzungsdatenModel extends AppKitchen.ModelBase<NutzungsdatenModelAttributes> {
    }

    export interface NutzungsdatenViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class NutzungsdatenView extends AppKitchen.ViewBase<NutzungsdatenModel> {
        public options: NutzungsdatenViewOptions;
        public requestCall: JQueryXHR;

        private container: {
            nutzungsdatenContainer: HTMLElement,
            nutzungsdatenForm: JQuery<HTMLElement>
        }

        private buttons: {
            submit: JQuery<HTMLElement>
        };

        private formModel: AppKitchen.Controls.Forms.FormModel;
        private formView: AppKitchen.Controls.Forms.FormViewBase;
        public dataLoader: AppKitchen.Data.EventDataLoader;

        constructor(model: NutzungsdatenModel, targetContainer: HTMLElement, options?: NutzungsdatenViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            if (!this.checkLocalStorage()) {
                this.setTemplate(Web.Templates.Nutzungsdaten);
                this.render();
            }
        }

        public render() {
            this.renderTemplate({});
            this.setContainers();
            this.setButtons();
            this.setEvents();
            this.createConfigurationForm();
            this.model.set({ loading: false });
            return this;
        }

        private checkLocalStorage(): boolean {
            //const bico2Usage = { "name": name, "organization": organization, "mail": mail };
            const bico2UsageRaw = localStorage.getItem("bico2-usage");
            if (bico2UsageRaw !== null) {
                try {
                    const bico2Usage = JSON.parse(bico2UsageRaw);

                    // if this is false, someone actively modified the local storage so the user need to add the data again
                    if (bico2Usage.name && bico2Usage.mail)
                    {
                        console.log("Found stored user data, skipping input mask");
                        this.submit(bico2Usage.name, bico2Usage.organization, bico2Usage.mail);
                        return true;
                    }
                } catch (e) {
                    console.error((e as Error).message);
                }
            }

            return false;
        }

        private setContainers(): void {
            this.container = {
                nutzungsdatenContainer: this.$el.find(".nutzungsdaten-container")[0],
                nutzungsdatenForm: this.$el.find(".nutzungsdaten-fields"),
            }
        }

        private setButtons(): void {
            this.buttons = {
                submit: this.$el.find(".submit-btn"),
            }
        }

        private setEvents(): void {
            this.buttons.submit.click(() => {
                
                let isValid = this.formView.validateFields();                
                const checkBoxView = this.formView.getFieldView("Check")
                const checkBoxValid = this.formModel.getValue("Check");
                isValid = isValid && checkBoxValid;
                if (!checkBoxValid) {
                    this.showMessage(checkBoxView.$el, AppKitchen.Strings.Form_ErrorMessage_Required, "warning");
                }

                if (!isValid) {
                    return;
                }

                this.submit(this.formModel.getValue('Name'),
                    this.formModel.getValue('Organization'),
                    this.formModel.getValue('Mail'));
            });
        }

        private submit(name, organization, mail): void {
            //this.dataLoader.getSaveRequestData();
            
            if (this.requestCall) {
                this.requestCall.abort();
            }
            const parameters = NutzungsdatenRoutingService.getParameters();

            // TODO: add commune and usage type
            const request: KEA.BW.Waermeplanung.Domain.NutzungsprotokollRequest = {
                Commune: parameters.commune,
                Mail: mail,
                // TODO: calculate this based on the return url
                UsageType: parameters.usageType,
                // organization is optional
                Organization: organization ? organization : "",
                Name: name
            };

            this.requestCall = AppKitchen.Data.getData("Nutzungsprotokoll",
                request,
                (response: boolean) => {
                    console.log("Upload usage protocol: " + response);
                    const bico2Usage = { "name": name, "organization": organization, "mail": mail };
                    localStorage.setItem('bico2-usage', JSON.stringify(bico2Usage));
                    console.log("Returning to Url: " + NutzungsdatenRoutingService.getParameters().returnUrl);

                    // route to the desired return url with only the needed parameters
                    const parameters = NutzungsdatenRoutingService.getParameters();
                    RoutingService.routeTo(new NutzungsdatenRoutingParameters(
                        parameters.commune, "", parameters.downloadPath, ""), parameters.returnUrl);
                },
                () => {
                    console.log("Failed to upload usage protocol");
                });
        }

        private createConfigurationForm(): void {

            const fieldModel: AppKitchen.Controls.Forms.FieldModel[] = [
                new AppKitchen.Controls.Forms.FieldModel('Name',
                    AppKitchen.Controls.Forms.FieldType.Text,
                    Strings.Nutzungsdaten_Name_Label,
                    {
                        mandatory: true,
                    }),
                new AppKitchen.Controls.Forms.FieldModel('Organization',
                    AppKitchen.Controls.Forms.FieldType.Text,
                    Strings.Nutzungsdaten_Organisation_Label,
                    {
                        mandatory: false,
                    }),
                new AppKitchen.Controls.Forms.FieldModel('Mail',
                    AppKitchen.Controls.Forms.FieldType.Text,
                    Strings.Nutzungsdaten_Mail_Label,
                    {
                        mandatory: true,
                    }),
                new AppKitchen.Controls.Forms.FieldModel('Check',
                    AppKitchen.Controls.Forms.FieldType.Checkbox,
                    Strings.Nutzungsdaten_Checkbox_Label,
                    {
                        default: null,
                        mandatory: true,
                        hideCheckboxLabel: true
                    }),
            ];

            this.formModel = new AppKitchen.Controls.Forms.FormModel(fieldModel, {});

            this.formView = new AppKitchen.Controls.Forms.TabularFormView(this.formModel, this.container.nutzungsdatenForm[0],
                {
                    editable: true,
                    showButtons: false,
                });

            this.formModel.onValueChange("Check", (value) => {
                if (value) {
                    const checkBoxView = this.formView.getFieldView("Check")
                    this.clearMessage(checkBoxView.$el);
                }
            })
        }

        clearMessage(element: JQuery<HTMLElement>) {
            element.removeClass("mandatory");
            element.removeClass("warning");
            element.removeClass("invalid");
            element.find(".a-input-error-message .a-message").html("");
        }

        showMessage(element: JQuery<HTMLElement>, message: string, errorClass: "mandatory" | "warning" | "invalid") {
            this.clearMessage(element);
            element.addClass(errorClass);
            element.find(".a-input-error-message .a-message").html(message);
        }
    }                  
}