module KEA.BW.Waermeplanung.Web.Services {

    export class ApplicationInfoService {

        public static instance: ApplicationInfoService = new ApplicationInfoService();

        private applicationInfo: Domain.ApplicationInfo;

        public init(onReady: (applicationInfo: Domain.ApplicationInfo) => void ): void {
            AppKitchen.Data.getDataApi("ApplicationInfo", null, applicationInfo => {
                this.applicationInfo = applicationInfo;
                onReady(this.applicationInfo);
            });
        }

        public getApplicationInfo(): Domain.ApplicationInfo {
            return this.applicationInfo;
        }

    }
}