/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {

    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;
    import SerializedParameterSet = BW.Waermeplanung.Web.Services.SerializedParameterSet;

    class RoutingParameters {
        public static readonly commune: string = "kommune";
    }

    export class StatistischeGrunddatenRoutingParameters extends ParametersBase {
        constructor(
            public readonly commune: string,
        ) { super() }

        public getParameterSet(): SerializedParameterSet {
            const param: SerializedParameterSet = {};
            param[RoutingParameters.commune] = encodeURI(this.commune);
            return param;
        }
    }

    export class StatistischeGrunddatenRoutingService {

        private static readParameters(): { [key: string]: string } {
            return AppKitchen.BrowserHelper.UrlQuery.getParameters();
        }

        private static writeParameters(parameters: { [key: string]: string }): void {
            Object.keys(parameters).forEach(k => AppKitchen.BrowserHelper.UrlQuery.setParameter(k, parameters[k]));
        }

        public static getParameters(): StatistischeGrunddatenRoutingParameters {
            const urlQuery = this.readParameters();
            return new StatistischeGrunddatenRoutingParameters(
                urlQuery[RoutingParameters.commune],
            );
        }

        public static setParameters(parameters: StatistischeGrunddatenRoutingParameters) : void {
            const p = {};
            p[RoutingParameters.commune] = parameters.commune;
            this.writeParameters(p);
        }

        public static getRoute(parameters: StatistischeGrunddatenRoutingParameters): string {
            return parameters.buildRoute(Apps.BICO2Routes.statistischeGrunddaten);
        }

        public static navigate(route: string): void {
            AppKitchen.getRouter().navigate(route, true);
        }

        public static routeTo(parameters: StatistischeGrunddatenRoutingParameters): void {
            this.navigate(this.getRoute(parameters));
        }
    }
}