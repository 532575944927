module KEA.BW.Waermeplanung.Web.Utils.Logout {
    import LoginManager = AppKitchen.LoginManager;

    export const logout = (e: any, $el: JQuery): void => {
        e.preventDefault();
        $el.fadeOut(400, () => {
            setTimeout(() => {
                AppKitchen.Data.Provider.LogoutProvider.logout().then(logoutResult => {
                    if (logoutResult && logoutResult.successfullyLoggedOut) {
                        // go back to the start page instead of oauth

                        AppKitchen.BrowserHelper.setUrl(AppKitchen.GlobalSettings.customConfig.ApplicationBaseURL);
                    } else {
                        AppKitchen.UIHelper.Notifications.notify(`Can't logout. Error message: '${logoutResult.errorMessage}'`, "warning");
                    }
                });
            }, 10);
        });
    }
}