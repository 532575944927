module KEA.BW.Waermeplanung.Web.Apps {

    export interface StatistischeGrunddatenAppOptions extends AppKitchen.AppBaseOptions {
        uploadWaermeplaeneApp: AppKitchen.FrameManager.RoutedApp<Apps.UploadWaermeplaeneAppOptions>;
    }

    export class StatistischeGrunddatenApp extends AppKitchen.AppBase<StatistischeGrunddatenAppOptions> {

        private model: Controls.StatistischeGrunddatenModel;

        start() {
            document.title = this.options.title = Strings.StatistischeGrunddaten_PageTitle;
            this.model = new Controls.StatistischeGrunddatenModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.StatistischeGrunddatenView(this.model, this.el,
                {
                });
        }

        dispose() {
        }
    }
}