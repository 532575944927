module KEA.BW.Waermeplanung.Web.Apps {

    export interface TimeSeriesBaseAppOptions extends AppKitchen.AppBaseOptions {
        headerTitle: string;
        importViewApp: AppKitchen.FrameManager.RoutedApp<Apps.ImportZeitreihenAppOptions>;
        dataRequestEndpoint: string;
        filterRequestEndpoint: string;
        treeViewTreeId: string;
    }

    export class TimeSeriesBaseApp<T extends TimeSeriesBaseAppOptions> extends AppKitchen.AppBase<T> {

        start() {
            document.title = this.options.title = Strings.TimeSeriesBase_PageTitle;
            const model = new Controls.TimeSeriesBaseModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.TimeSeriesBaseView(model, this.el,
                {
                    headerTitle: this.options.headerTitle,
                    importViewApp: this.options.importViewApp,
                    dataRequestEndpoint: this.options.dataRequestEndpoint,
                    filterRequestEndpoint: this.options.filterRequestEndpoint,
                    excelFileName: "",
                    treeViewTreeId: this.options.treeViewTreeId
                });
        }

        dispose() {
        }
    }
}