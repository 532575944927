module KEA.BW.Waermeplanung.Web.Apps {

    export interface KommunenSteckbriefAppOptions extends AppKitchen.AppBaseOptions {
        uploadWaermeplaeneApp: AppKitchen.FrameManager.RoutedApp<Apps.UploadWaermeplaeneAppOptions>;
    }

    export class KommunenSteckbriefApp extends AppKitchen.AppBase<KommunenSteckbriefAppOptions> {

        private model: Controls.KommunenSteckbriefModel;

        start() {
            document.title = this.options.title = Strings.KommunenSteckbrief_PageTitle;
            this.model = new Controls.KommunenSteckbriefModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.KommunenSteckbriefView(this.model, this.el,
                {
                });
        }

        dispose() {
        }
    }
}