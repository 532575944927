module KEA.BW.Waermeplanung.Web.Apps {

    export interface DashboardAppOptions extends AppKitchen.AppBaseOptions {
        defaultApp?: string;
    }

    export class DashboardApp extends AppKitchen.AppBase<DashboardAppOptions> {

        start() {
            //document.title = this.options.title = "Dashboard";
            //AppKitchen.UIHelper.renderTemplateTo(this.$el[0], Templates.Dashboard, { title: "Dashboard Title" });

            // no dashboard, just forward to the default app
            AppKitchen.BrowserHelper.setUrl(`#/${this.options.defaultApp}/`);
        }

        dispose() {
        }
    }
}