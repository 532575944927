/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {

    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;
    import SerializedParameterSet = BW.Waermeplanung.Web.Services.SerializedParameterSet;
    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;

    class RoutingParameters {
        public static readonly MeldelisteId: string = "meldeliste";
    }

    export class DeteilansichtMeldungenRoutingParameters extends ParametersBase {
        constructor(
            public readonly meldelisteId: string,
        ) { super() }

        public getParameterSet(): SerializedParameterSet {
            const param: SerializedParameterSet = {};
            param[RoutingParameters.MeldelisteId] = escape(this.meldelisteId);
            return param;
        }
    }

    export class DeteilansichtMeldungenRoutingService extends RoutingService {
        
        public static getParameters(): DeteilansichtMeldungenRoutingParameters {
            const urlQuery = this.readParameters();
            return new DeteilansichtMeldungenRoutingParameters(
                urlQuery[RoutingParameters.MeldelisteId],
            );
        }

        public static setParameters(parameters: DeteilansichtMeldungenRoutingParameters) : void {
            const p = {};
            p[RoutingParameters.MeldelisteId] = parameters.meldelisteId;
            this.writeParameters(p);
        }

        public static getRoute(parameters: DeteilansichtMeldungenRoutingParameters): string {
            return parameters.buildRoute(Apps.Routes.DeteilansichtMeldungen);
        }
    }
}