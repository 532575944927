/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {
    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;

    class RoutingParameters {
        public static readonly TimeseriesViewId: string = "timeseriesViewId";
        public static readonly Commune: string = "commune";
    }

    export class TimeSeriesBaseRoutingParameters extends RoutingParameters {

        constructor(
            public readonly timeseriesViewId: string,
            public readonly commune: string = ""
        ) { super() }
    }

    export class TimeSeriesBaseRoutingService extends RoutingService{

        public static getParameters(): TimeSeriesBaseRoutingParameters {
            const urlQuery = this.readParameters();
            return new TimeSeriesBaseRoutingParameters(
                urlQuery[RoutingParameters.TimeseriesViewId],
                urlQuery[RoutingParameters.Commune]
            );
        }

        public static setParameters(parameters: TimeSeriesBaseRoutingParameters) : void {
            const p = {};
            p[RoutingParameters.TimeseriesViewId] = parameters.timeseriesViewId;
            p[RoutingParameters.Commune] = parameters.commune;
            this.writeParameters(p);
        }
    }
}