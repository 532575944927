module KEA.BW.Waermeplanung.Web.Apps {

    export interface NutzungsdatenAppOptions extends AppKitchen.AppBaseOptions {
        importViewApp: AppKitchen.FrameManager.RoutedApp<Apps.NutzungsdatenAppOptions>;
    }

    export class NutzungsdatenApp extends AppKitchen.AppBase<NutzungsdatenAppOptions> {

        start() {
            document.title = this.options.title = Strings.Nutzungsdaten_PageTitle;
            const model = new Controls.NutzungsdatenModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.NutzungsdatenView(model, this.el,
                {
                });
        }

        dispose() {
        }
    }
}