/// <reference path="KEABaseApp.ts"/>

namespace KEA.BW.Waermeplanung.Web.Apps {

    export class EnergiedatenRoutes {
        public static readonly Dashboard: string = "";
        public static readonly ZeitreihenEnergiedaten: string = "zeitreihen-energiedaten";
        public static readonly ImportZeitreihenEnergiedaten: string = "import-zeitreihen-energiedaten";
        public static readonly Auswertung: string = "auswertung";
    }

    export class EnergiedatenMainApp extends KEABaseApp {

        getRoutedAppsAndMenu(): [apps: AppKitchen.FrameManager.RoutedApp<any>[],
            menu: AppKitchen.FrameManager.MenuItem] {

            const importZeitreihenEnergiedaten = new AppKitchen.FrameManager.RoutedApp<ImportZeitreihenAppOptions>(Apps.ImportZeitreihenApp, EnergiedatenRoutes.ImportZeitreihenEnergiedaten,
                {
                    dataLoaderDocumentName: "Energiedaten",
                    dataLoaderDocumentType: "Import",
                    inventoryId: "Importvorgaenge_Energiedaten",
                    title: Strings.ImportZeitreihenEnergiedaten_PageTitle
                });

            const zeitreihenEnergiedaten = new AppKitchen.FrameManager.RoutedApp<TimeSeriesBaseAppOptions>(Apps.TimeSeriesBaseApp, EnergiedatenRoutes.ZeitreihenEnergiedaten,
                {
                    headerTitle: Strings.ZeitreihenEnergiedaten_Title,
                    importViewApp: importZeitreihenEnergiedaten,
                    dataRequestEndpoint: "ZeitreihenEnergiedaten",
                    filterRequestEndpoint: "ZeitreihenEnergiedatenTimeseriesFilter",
                    treeViewTreeId: AppKitchen.GlobalSettings.customConfig.CommuneTreeEnergiedaten
                });

            const evaluations = new AppKitchen.FrameManager.RoutedApp<EvaluationsAppOptions>(
                Apps.EvaluationsApp, Routes.Auswertung,
                {
                    headerTitle: Strings.Evaluations_Title,
                    importViewApp: null,
                    dataRequestEndpoint: "ZeitreihenBerichteEnergiedaten",
                    filterRequestEndpoint: "ZeitreihenBerichteEnergiedatenTimeseriesFilter",
                    templateFolder: AppKitchen.GlobalSettings.customConfig.TemplateEnergiedatenAuswertungen,
                    dropDownLabel: Strings.Evaluations_DropdownLabel,
                    treeViewTreeId: AppKitchen.GlobalSettings.customConfig.CommuneTree
                });

            const dashboard = new AppKitchen.FrameManager.RoutedApp<DashboardAppOptions>(Apps.DashboardApp, Routes.Dashboard,
                {
                    defaultApp: zeitreihenEnergiedaten.route
                });

            const routedApps = [
                dashboard,
                zeitreihenEnergiedaten,
                importZeitreihenEnergiedaten,
                evaluations
            ];

            const menu = new AppKitchen.FrameManager.MenuItem(Strings.Menu_PageTitle, null,
                [
                    new AppKitchen.FrameManager.MenuItem(Strings.Menu_ZeitreihenEnergiedaten, { route: zeitreihenEnergiedaten.route, highlightOnNavigate: true }),
                    new AppKitchen.FrameManager.MenuItem(Strings.Evaluations_PageTitle, { route: evaluations.route, highlightOnNavigate: true }),
                ]);

            return [routedApps, menu];
        }

        getTemplate(): string {
            return Templates.EnergiedatenMainApp;
        }
    }
}