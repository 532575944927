module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;

    export interface DeteilansichtMeldungenModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class DeteilansichtMeldungenModel extends AppKitchen.ModelBase<DeteilansichtMeldungenModelAttributes> {
    }

    export interface DeteilansichtMeldungenViewOptions extends AppKitchen.ViewBaseOptions {
        uploadWaermeplaeneApp: AppKitchen.FrameManager.RoutedApp<Apps.UploadWaermeplaeneAppOptions>;
    }

    interface PruefstatusDialog extends AppKitchen.AppBaseOptions {
        info: string;
        fileName: string;
        pruefstatus: string;
        anmerkung: string;
        submit: (pruefstatus: string, anmerkung: string) => void;
        cancel: () => void;
    }

    class PruefstatusDialog extends AppKitchen.AppBase<PruefstatusDialog> {

        private containers: {
            form: HTMLElement;
        }

        private buttons: {
            cancel: JQuery<HTMLElement>,
            submit: JQuery<HTMLElement>,
        }

        private formModel: AppKitchen.Controls.Forms.FormModel;

        start(): void {
            this.renderTemplate();
            this.setContainers();
            this.setButtons();
            this.setEvents();

            this.createForm();
        }

        private renderTemplate(): void {
            AppKitchen.UIHelper.renderTemplateTo(this.el, Templates.PruefstatusDialog, { info: this.options.info });
        }

        private setContainers(): void {
            this.containers = {
                form: this.$el.find<HTMLElement>(".form-container")[0],
            }
        }

        private setButtons(): void {
            this.buttons = {
                cancel: this.$el.find<HTMLElement>(".cancel-btn"),
                submit: this.$el.find<HTMLElement>(".submit-btn"),
            }
        }

        private setEvents(): void {
            this.buttons.cancel.click(() => this.options.cancel());
            this.buttons.submit.click(() => this.options.submit(this.formModel.getValue('Pruefstatus'), this.formModel.getValue('Anmerkung')));
        }

        private createForm(): void {
            const fieldModel: AppKitchen.Controls.Forms.FieldModel[] = [
                new AppKitchen.Controls.Forms.FieldModel('Pruefstatus',
                    AppKitchen.Controls.Forms.FieldType.Text,
                    Strings.PruefstatusDialog_Pruefstatus_Label,
                    {
                        pool: {
                            values: [
                                { id: Prüfstatus.Freigegeben, label: Prüfstatus.Freigegeben },
                                { id: Prüfstatus.MaengelFestgestellt, label: Prüfstatus.MaengelFestgestellt },
                                { id: Prüfstatus.PruefungOffen, label: Prüfstatus.PruefungOffen }
                            ],
                            valueField: 'id',
                            textField: 'label',
                            restrict: true
                        },
                        mandatory: true,
                    }),
                new AppKitchen.Controls.Forms.FieldModel('Anmerkung',
                    AppKitchen.Controls.Forms.FieldType.Text,
                    Strings.PruefstatusDialog_Anmerkung_Label,
                    {
                        multiline: 8
                    })
            ];
            this.formModel = new AppKitchen.Controls.Forms.FormModel(fieldModel, {});

            new AppKitchen.Controls.Forms.TabularFormView(this.formModel, this.containers.form, { editable: true, showButtons: false });

            this.formModel.setFieldsData(
                {
                    'Pruefstatus': this.options.pruefstatus || Prüfstatus.Freigegeben,
                    'Anmerkung': this.options.anmerkung
                }
            );
        }
    }

    interface MeldedatenFestlegenDialog extends AppKitchen.AppBaseOptions {
        meldeDatum: string;
        melder: string;
        submit: (meldeDatum: string, melder: string) => void;
        cancel: () => void;
    }

    class MeldedatenFestlegenDialog extends AppKitchen.AppBase<MeldedatenFestlegenDialog> {

        private containers: {
            form: HTMLElement;
        }

        private buttons: {
            cancel: JQuery<HTMLElement>,
            submit: JQuery<HTMLElement>,
        }

        private formModel: AppKitchen.Controls.Forms.FormModel;
        private formView: AppKitchen.Controls.Forms.FormViewBase;

        start(): void {
            this.renderTemplate();
            this.setContainers();
            this.setButtons();
            this.setEvents();

            this.createForm();
        }

        private renderTemplate(): void {
            AppKitchen.UIHelper.renderTemplateTo(this.el, Templates.SetMeldedaten);
        }

        private setContainers(): void {
            this.containers = {
                form: this.$el.find<HTMLElement>(".form-container")[0],
            }
        }

        private setButtons(): void {
            this.buttons = {
                cancel: this.$el.find<HTMLElement>(".cancel-btn"),
                submit: this.$el.find<HTMLElement>(".submit-btn"),
            }
        }

        private setEvents(): void {
            this.buttons.cancel.click(() => this.options.cancel());
            this.buttons.submit.click(() => {
                const isValid = this.formView.validateFields();
                if (!isValid) {
                    return;
                }
                
                this.options.submit(this.formModel.getValue('Meldedatum'), this.formModel.getValue('Melder'))                
            });
            
        }

        private createForm(): void {
            const fieldModel: AppKitchen.Controls.Forms.FieldModel[] = [
                new AppKitchen.Controls.Forms.FieldModel('Meldedatum',
                    AppKitchen.Controls.Forms.FieldType.DateTime,
                    'Meldedatum',
                    {
                        mandatory: true,
                    }),
                new AppKitchen.Controls.Forms.FieldModel('Melder',
                    AppKitchen.Controls.Forms.FieldType.Text,
                    'Melder',
                    {
                        mandatory: true,
                    })
            ];
            this.formModel = new AppKitchen.Controls.Forms.FormModel(fieldModel, {});
            this.formView =  new AppKitchen.Controls.Forms.TabularFormView(this.formModel, this.containers.form, { editable: true, showButtons: false });

            this.formModel.setFieldsData(
                {
                    'Meldedatum': this.options.meldeDatum,
                    'Melder': this.options.melder
                }
            );
        }
    }

    export class DeteilansichtMeldungenView extends AppKitchen.ViewBase<DeteilansichtMeldungenModel> {
        public options: DeteilansichtMeldungenViewOptions;

        private bilanzGridModel: AppKitchen.Controls.Grids.EventGridModel;
        private gridModel: AppKitchen.Controls.Grids.EventGridModel;
        public blianzDataLoader: AppKitchen.Data.EventDataLoader;
        public dataLoader: AppKitchen.Data.EventDataLoader;
        public eventDataProvider: AppKitchen.Data.Provider.EventDataProvider;

        private container: {
            configuration: HTMLElement,
            bilanzdaten: HTMLElement,
            waermeplaene: HTMLElement,
        }

        private buttons: {
            goBack: JQuery<HTMLElement>,
            setMeldedaten: JQuery<HTMLElement>,
            uploadWaermeplaene: JQuery<HTMLElement>,
        }

        constructor(model: DeteilansichtMeldungenModel, targetContainer: HTMLElement, options?: DeteilansichtMeldungenViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, { uploadWaermeplaeneApp: null }));

            this.setTemplate(Web.Templates.DeteilansichtMeldungen);

            this.render();
        }

        public render() {
            this.renderTemplate({});
            this.setContainers();
            this.setButtons();
            this.setEvents();

            //this.model.set({ loading: true });
            this.createBilanzdatenGrid();
            this.createWaermeplaeneGrid();
            this.createConfigurationForm();

            return this;
        }

        private setContainers(): void {
            this.container = {
                configuration: this.$el.find(".configuration")[0],
                bilanzdaten: this.$el.find(".bilanzdaten-grid-container")[0],
                waermeplaene: this.$el.find(".waermeplaene-grid-container")[0],
            }
        }

        private setButtons(): void {
            this.buttons = {
                goBack: this.$el.find<HTMLLIElement>(".go-back-button"),
                setMeldedaten: this.$el.find<HTMLLIElement>(".set-meldedaten-button"),
                uploadWaermeplaene: this.$el.find<HTMLLIElement>(".upload-waermeplaene-button"),
            }
        }

        private setEvents(): void {
            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
            if (applicationInfo.IsWaermeplaner) {
                this.buttons.setMeldedaten.click(() => this.openSetMeldedatenDialog());
                this.buttons.uploadWaermeplaene.click(() => this.openUploadWaermeplaeneDialog());
            } else {
                this.buttons.setMeldedaten.remove();
                this.buttons.uploadWaermeplaene.remove();
            }
        }

        private openSetMeldedatenDialog(): void {
            let window: kendo.ui.Window;
            this.blianzDataLoader.loadData({
                success: () => {
                    let meldeDatum = null;
                    let melder = null;
                    const data = this.blianzDataLoader.getData();
                    if (data.length === 1) {
                        meldeDatum = data[0]['Meldeliste_Meldedatum'];
                        melder = data[0]['Meldeliste_Melder'];

                        if (!meldeDatum) {
                            meldeDatum = new Date();
                        }             

                        if (!melder) {
                            // no E-Mail adress is saved, use the current user one
                            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
                            const user = Services.UserInfoService.instance.getUserInfo(applicationInfo.UserId);
                            // TODO: check if User and Email are valid
                            melder = user.Email;
                        }

                        const appOptions: MeldedatenFestlegenDialog = {
                            meldeDatum: meldeDatum,
                            melder: melder,
                            submit: (meldeDatum, melder) => {                                                                
                                data[0]['Meldeliste_Meldedatum'] = meldeDatum;
                                data[0]['Meldeliste_Melder'] = melder;

                                // make sure the pruefstatus field is set
                                if (!data[0]['Meldeliste_Pruefstatus']) {
                                    data[0]['Meldeliste_Pruefstatus'] = "Prüfung offen";
                                }

                                const saveRequest = this.blianzDataLoader.getSaveRequestData(data[0]);
                                AppKitchen.Data.Api.updateEventData(saveRequest,
                                    (result) => {
                                        console.log(result);
                                        this.blianzDataLoader.loadData();
                                        window.close();
                                        AppKitchen.UIHelper.Notifications.notify(Strings.SetMeldedaten_Set_Notification, "success",
                                            { title: Strings.SetMeldedaten_Notification_Title, closeAfter: 5000 });
                                    },
                                    (requestData, xhr, statusText, errorText) => {
                                        AppKitchen.UIHelper.Notifications.notify(Strings.SetMeldedaten_Set_Error_Notification + ` '${errorText}'`,
                                            "warning", 
                                            { title: Strings.SetMeldedaten_Fail_Notification_Title, closeAfter: 5000 });
                                    }
                                );
                            },
                            cancel: () => {
                                window.close();
                            }
                        } as any;
                        const windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                            buttons: [],
                            modal: true,
                            easyClose: false,
                            width: "40rem",
                            height: "22rem",
                        };

                        window = new AppKitchen.FrameManager.RoutedApp<MeldedatenFestlegenDialog>(MeldedatenFestlegenDialog, "").startInWindow(
                            windowOptions,
                            appOptions);

                    } else {
                        console.error(`Found more or less then one entry!`);
                    }
                }
            });            
        }

        private window: kendo.ui.Window;
                     
        private openUploadWaermeplaeneDialog(): void {
            const appOptions: Apps.UploadWaermeplaeneAppOptions = { close: () => this.closeApp() };
            const windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                buttons: [
                    //{ name: "undock", action: (window) => this.undockWindow(window) },
                    { name: "close", action: (window) => window.close() }
                ],
                //title: Strings.UploadWaermeplaene_Title,
                modal: true,
                easyClose: false,
                width: "900px",
                closed: () => {
                    this.dataLoader.loadData();
                    // refocus grid on close (if browser window still focused)
                    //if (document.hasFocus()) this.focusGrid();
                }
            };

            // open window
            this.window = this.options.uploadWaermeplaeneApp.startInWindow(windowOptions, appOptions);
        }

        private closeApp(): void {
            if (this.window) {
                this.window.close();
            }
        }

        //private undockWindow(window: kendo.ui.Window) {
        //    AppKitchen.BrowserHelper.openInWindow("#/" + this.options.uploadWaermeplaeneApp.route + "/", true);
        //    window.close();
        //}

        private createConfigurationForm(): void {
            const berichtsjahrField = new AppKitchen.Controls.Forms.FieldModel('Berichtsjahr',
                AppKitchen.Controls.Forms.FieldType.Year,
                Strings.Field_Berichtsjahr,
                {});
            const communeField = new AppKitchen.Controls.Forms.FieldModel('Commune',
                AppKitchen.Controls.Forms.FieldType.Text,
                Strings.Field_Commune,
                {});
            const formModel = new AppKitchen.Controls.Forms.FormModel([berichtsjahrField, communeField], {});

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.HorizontalTabularFormView(formModel, this.container.configuration,
                {
                    editable: false,
                    showButtons: false,
                });

            const parameters = DeteilansichtMeldungenRoutingService.getParameters();

            if (parameters === null || parameters.meldelisteId === null) {
                return;
            }

            const config: AppKitchen.Data.GridConfig = JSON.parse(Data.getDocumentSync("Meldeliste", "DeteilansichtMeldungen"));

            config.EventItemRestrictions[0].Value = parameters.meldelisteId;

            this.eventDataProvider = new AppKitchen.Data.Provider.EventDataProvider();
            this.eventDataProvider
                .load(config, "EventData")
                .then(data => {
                    if (data.length === 1) {
                        const berichtsjahr: Date = data[0]['Meldeliste_Berichtsjahr'];
                        const commune: Date = data[0]['Meldeliste_NavObject_Reg_Name'];

                        this.buttons.goBack.attr("href", MeldelisteRoutingService.getRoute(new MeldelisteRoutingParameters(berichtsjahr)));
                        formModel.setFieldsData({
                            Berichtsjahr: berichtsjahr,
                            Commune: commune
                        });
                    } else {
                        console.error(`Found more or less then one entry!`);
                    }
                });
        }

        private createBilanzdatenGrid(): void {
            const parameters = DeteilansichtMeldungenRoutingService.getParameters();

            if (parameters === null || parameters.meldelisteId === null) {
                return;
            }

            const config: AppKitchen.Data.GridConfig = JSON.parse(Data.getDocumentSync("Bilanzdaten", "DeteilansichtMeldungen"));

            config.EventItemRestrictions = [{
                ItemId: "Id",
                InventoryId: "Meldeliste",
                Operation: "AND",
                Value: parameters.meldelisteId
            }];

            this.blianzDataLoader = new AppKitchen.Data.EventDataLoader(config, () => {
                this.bilanzGridModel = new DetailansichtMeldungBilanzdatenGridModel(this.blianzDataLoader, {});
                new DeteilansichtMeldungBilanzdatenGridView(this.bilanzGridModel, this.container.bilanzdaten,
                    {
                        fillHeight: false,
                        initialSort: [
                            { field: 'Meldeliste_Meldedatum', dir: 'asc' },
                            { field: 'Meldeliste_Pruefstatus', dir: 'desc' }
                        ],
                        templates: [
                            { field: 'Meldeliste_Pruefstatus', template: (model) => Prüfstatus.translateStatus(model, 'Meldeliste_Pruefstatus') },
                        ]
                    });

                this.blianzDataLoader.loadData();

                AppKitchen.UIHelper.updateFullHeightGrids(this.container.bilanzdaten);                
            });
        }

        private createWaermeplaeneGrid(): void {
            const parameters = DeteilansichtMeldungenRoutingService.getParameters();

            if (parameters === null || parameters.meldelisteId === null) {
                return;
            }

            const config: AppKitchen.Data.GridConfig = JSON.parse(Data.getDocumentSync("Waermeplaene", "DeteilansichtMeldungen"));

            config.EventItemRestrictions = [{
                ItemId: "Id",
                InventoryId: "Meldeliste",
                Operation: "AND",
                Value: parameters.meldelisteId
            }];

            this.dataLoader = new AppKitchen.Data.EventDataLoader(config, () => {
                this.gridModel = new DetailansichtMeldungGridModel(this.dataLoader, {});
                new DeteilansichtMeldungGridView(this.gridModel, this.container.waermeplaene,
                    {
                        fillHeight: true,
                        initialSort: [
                            { field: 'Wärmepläne_Dokumententyp', dir: 'asc' },
                            { field: 'Wärmepläne_Meldedatum', dir: 'desc' }
                        ],
                        templates: [
                            { field: 'Wärmepläne_Pruefstatus', template: (model) => Prüfstatus.translateStatus(model, 'Wärmepläne_Pruefstatus') },
                            { field: 'Wärmepläne_Anmerkung', template: (model) => DeteilansichtMeldungenView.translateAnmerkung(model) },
                        ]
                    });

                this.refreshData();

                AppKitchen.UIHelper.updateFullHeightGrids(this.el);
            });
        }

        private addTooltip(el: JQuery) {
            el.kendoTooltip({
                position: "top",
                show: e => AppKitchen.UIHelper.hideTooltips(e.sender)
            });
        }

        private refreshData(): void {
            this.dataLoader.loadData({
                success: () => {
                    this.$el.find('.anmerkung').each((i, e) => this.addTooltip($(e)));
                }
            });
        }

        private static translateAnmerkung(model: any): string {
            const anmerkung = model['Wärmepläne_Anmerkung'];
            return `<span class="anmerkung" title="${anmerkung}">${anmerkung}</span>`;
        }

    }

    export class DetailansichtMeldungBilanzdatenGridModel extends AppKitchen.Controls.Grids.EventGridModel {

        private applicationInfo: Domain.ApplicationInfo;
        public dataLoader: AppKitchen.Data.EventDataLoader;

        constructor(dataLoader: AppKitchen.Data.EventDataLoader, options?: AppKitchen.Controls.Grids.EventGridOptions) {
            super(dataLoader, AppKitchen.OptionsHelper.merge(options, <AppKitchen.Controls.Grids.EventGridOptions>{
                getColumnHeader: DetailansichtMeldungBilanzdatenGridModel.getColumnHeader
            }));

            this.applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();

            var columns = this.addVirtualColumns();
            this.set({ columns: columns });
            this.dataLoader = dataLoader;
        }

        private static getColumnHeader(inventoryId: string, itemId: string, eventItem: AppKitchen.Api.Models.EventItemInfo): string {
            return Strings["Field_" + eventItem.Name] || eventItem.Name;
        }

        private addVirtualColumns(): kendo.ui.GridColumn[] {
            const columns: kendo.ui.GridColumn[] = [];
            this.get().columns
                .forEach(c => columns.push(c));

            const actionField = {
                field: "Actions",
                title: Strings.DeteilansichtMeldungen_GridColumn_Actions_Label,
                width: "260px",
                template: (m) => this.templateActions(m),
                filterable: false,
                groupable: false,
                sortable: false
            }

            //columns.splice(2, 0, waermeplaeneAnazhl);
            columns.push(actionField);

            return columns;
        }

        private templateActions(model: any): string {
            let template = '';

            if (this.applicationInfo.IsRP_Pruefer) {
                template += `<button class="k-button set-inspection-status-btn">${Strings.DeteilansichtMeldungen_SetInspectionState_Button}</button>`;
            }
    
            return template;
        }
    }

    export class DeteilansichtMeldungBilanzdatenGridView extends AppKitchen.Controls.Grids.GridView {
        public model: AppKitchen.Controls.Grids.EventGridModel;

        constructor(model: AppKitchen.Controls.Grids.EventGridModel, targetContainer: HTMLElement, options: AppKitchen.Controls.Grids.GridOptions) {
            super(model, targetContainer, options);
        }

        protected dataBound(e) {
            super.dataBound(e);

            const rows = e.sender.tbody.children();
            for (let j = 0; j < rows.length; j++) {
                const row = $(rows[j]);
                const dataItem = e.sender.dataItem(row);

                if (dataItem['Meldedaten_Pruefstatus'] === Prüfstatus.Freigegeben) {
                    row.addClass("k-irrelevant");
                }

                //const berichtsjahr: Date = dataItem['Meldeliste_Berichtsjahr'];

                row.find(".set-inspection-status-btn").click(() => this.openSetPruefstatusDialog(dataItem['Meldeliste_Id'], dataItem['Meldeliste_Pruefstatus'], dataItem['Meldeliste_Anmerkung']));
            }
        }
        
        private openSetPruefstatusDialog(id: string, pruefstatus: string, anmerkung: string): void {
            let window: kendo.ui.Window;
            const appOptions: PruefstatusDialog = {
                info: Strings.PruefstatusDialog_Info_SetMeldedaten,
                pruefstatus: pruefstatus,
                anmerkung: anmerkung,
                submit: (pruefstatus, anmerkung) => {
                    window.close();
                    this.setPruefstatus(id, pruefstatus, anmerkung);
                },
                cancel: () => {
                    window.close();
                }
            } as any;
            const windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                buttons: [],
                modal: true,
                easyClose: false,
                width: "40rem",
                height: "30rem",
                closed: () => { }
            };

            window = new AppKitchen.FrameManager.RoutedApp<PruefstatusDialog>(PruefstatusDialog, "").startInWindow(
                windowOptions,
                appOptions);
        }

        private setPruefstatus(id: string, pruefstatus: string, anmerkung: string): void {

            const data = this.model.dataLoader.getData();
            if (data.length === 1) {
                data[0]['Meldeliste_Pruefstatus'] = pruefstatus;
                data[0]['Meldeliste_Anmerkung'] = anmerkung;
                const saveRequest = this.model.dataLoader.getSaveRequestData(data[0]);
                AppKitchen.Data.Api.updateEventData(saveRequest,
                    (result) => {
                        console.log(result);
                        this.model.dataLoader.loadData();
                        window.close();
                        AppKitchen.UIHelper.Notifications.notify(Strings.SetMeldedaten_Set_Notification, "success",
                            { title: Strings.SetMeldedaten_Notification_Title, closeAfter: 5000 });
                    },
                    (requestData, xhr, statusText, errorText) => {
                        AppKitchen.UIHelper.Notifications.notify(Strings.SetMeldedaten_Set_Error_Notification + ` '${errorText}'`,
                            "warning",
                            { title: Strings.SetMeldedaten_Fail_Notification_Title, closeAfter: 5000 });
                    }
                );
             }
        
            
            //AppKitchen.Data.getDataApi('WaermeplanSetPruefstatus',
            //    { Id: id, Pruefstatus: pruefstatus, Anmerkung: anmerkung, ClearAnmerkung: anmerkung === '' } as Domain.WaermeplanSetPruefstatusRequest,
            //    success => {
            //        if (success) {
            //            this.model.dataLoader.loadData();
            //            AppKitchen.UIHelper.Notifications.notify(`Der Prüfstatus des Wärmeplan '${fileName}' wurde erfolgreich gesetzt.`, "success", { title: 'Prüfstatus', closeAfter: 5000 });
            //        }
            //        this.model.set({ loading: false });
            //    }, error => this.model.set({ loading: false }));
        }

    }

    export class DetailansichtMeldungGridModel extends AppKitchen.Controls.Grids.EventGridModel {

        private applicationInfo: Domain.ApplicationInfo;

        constructor(dataLoader: AppKitchen.Data.EventDataLoader, options?: AppKitchen.Controls.Grids.EventGridOptions) {
            super(dataLoader, AppKitchen.OptionsHelper.merge(options, <AppKitchen.Controls.Grids.EventGridOptions>{
                getColumnHeader: DetailansichtMeldungGridModel.getColumnHeader
            }));

            this.applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();

            var columns = this.addVirtualColumns();
            this.set({ columns: columns });
        }

        private static getColumnHeader(inventoryId: string, itemId: string, eventItem: AppKitchen.Api.Models.EventItemInfo): string {
            return Strings["Field_" + eventItem.Name] || eventItem.Name;
        }

        private addVirtualColumns(): kendo.ui.GridColumn[] {
            const columns: kendo.ui.GridColumn[] = [];
            this.get().columns
                .forEach(c => columns.push(c));

            this.translateUserColumn(columns, 'Wärmepläne_Melder');
            this.translateUserColumn(columns, 'Wärmepläne_Pruefstatus_ChangeId');

            const actionField = {
                field: "Actions",
                title: Strings.DeteilansichtMeldungen_GridColumn_Actions_Label,
                width: "260px",
                template: (m) => this.templateActions(m),
                filterable: false,
                groupable: false,
                sortable: false
            }

            //columns.splice(2, 0, waermeplaeneAnazhl);
            columns.push(actionField);

            return columns;
        }

        private translateUserColumn(columns: kendo.ui.GridColumn[], columnId: string): void {
            columns.filter(c => c.field === columnId).forEach(c => c.template = (model) => {
                const value = model[columnId];
                const userInfo = Services.UserInfoService.instance.getUserInfo(value);
                if (!userInfo) {
                    console.warn(`Can not read user info for user: '${value}'.`);
                    return value;
                }
                if (!userInfo.Email) {
                    console.warn(`No email was configured for user: '${value}'.`);
                    return userInfo.Name;
                }
                return `<a href="mailto:${userInfo.Email}">${userInfo.Name}</a>`;
            });
        }

        private templateActions(model: any): string {
            let template = '';
            if (model['Wärmepläne_Pruefstatus'] !== Prüfstatus.Zurueckgezogen) {
                if (model['Wärmepläne_Dokument']) {
                    template += `<button class="k-button download-btn">${Strings.DeteilansichtMeldungen_Download_Button}</button>`;

                    if (this.applicationInfo.IsRP_Pruefer) {
                        template += `<button class="k-button set-inspection-status-btn">${Strings.DeteilansichtMeldungen_SetInspectionState_Button}</button>`;
                    }
                    if (this.applicationInfo.IsWaermeplaner) {
                        template += `<button class="k-button cancel-btn">${Strings.DeteilansichtMeldungen_Cancel_Button}</button>`;
                    }
                }
            }
            return template;
        }

    }

    export class DeteilansichtMeldungGridView extends AppKitchen.Controls.Grids.GridView {
        public model: AppKitchen.Controls.Grids.EventGridModel;

        constructor(model: AppKitchen.Controls.Grids.EventGridModel, targetContainer: HTMLElement, options: AppKitchen.Controls.Grids.GridOptions) {
            super(model, targetContainer, options);
        }

        protected dataBound(e) {
            super.dataBound(e);

            const rows = e.sender.tbody.children();
            for (let j = 0; j < rows.length; j++) {
                const row = $(rows[j]);
                const dataItem = e.sender.dataItem(row);

                if (dataItem['Wärmepläne_Pruefstatus'] === Prüfstatus.Zurueckgezogen) {
                    row.addClass("k-irrelevant");
                }

                row.find(".download-btn").click(() => this.downloadFile(dataItem['Wärmepläne_Id']));
                row.find(".cancel-btn").click(() => this.openCancelFileDialog(dataItem['Wärmepläne_Id'], dataItem['Wärmepläne_Dokument']));
                row.find(".set-inspection-status-btn").click(() => this.openSetPruefstatusDialog(dataItem['Wärmepläne_Id'], dataItem['Wärmepläne_Dokument'], dataItem['Wärmepläne_Pruefstatus'], dataItem['Wärmepläne_Anmerkung']));

                row.find(".cancel-btn").kendoTooltip(
                    {
                        content: () => "Wählen Sie Zurückziehen um einen bereits eingereichten <br/>Wärmeplan als gelöscht zu kennzeichnen. Anschließend können Sie erneut <br/>einen Wärmeplan für das zurückgezogene Dokument einreichen",
                        position: "bottom"
                    }).data("kendoTooltip");
            }
        }

        private openCancelFileDialog(id: string, fileName: string): void {
            AppKitchen.UIHelper.DialogBoxes.confirm(`Möchten Sie den Wärmeplan '${fileName}' zurückziehen?`, () => this.cancelFile(id, fileName),
                { strings: { ok: "Zurückziehen", cancel: AppKitchen.Strings.Cancel },title: 'Datei zurückziehen' });
        }

        private cancelFile(id: string, fileName: string): void {
            this.model.set({ loading: true });
            AppKitchen.Data.getDataApi('WaermeplanSetPruefstatus',
                { Id: id, Pruefstatus: Prüfstatus.Zurueckgezogen } as Domain.WaermeplanSetPruefstatusRequest,
                success => {
                    if (success) {
                        this.model.set({ loading: false });
                        this.model.dataLoader.loadData();
                        AppKitchen.UIHelper.Notifications.notify(`Der Wärmeplan '${fileName}' wurde erfolgreich zurückgezogen.`, "success", {title: 'Zurückgezogen', closeAfter: 5000});
                    }
                }, error => this.model.set({ loading: false }));
        }

        private openSetPruefstatusDialog(id: string, fileName: string, pruefstatus: string, anmerkung: string): void {
            let window: kendo.ui.Window;
            const appOptions: PruefstatusDialog = {
                info: Utils.StringUtils.format(Strings.PruefstatusDialog_Info_UploadWaermeplaene, fileName),
                fileName: fileName,
                pruefstatus: pruefstatus,
                anmerkung: anmerkung,
                submit: (pruefstatus, anmerkung) => {
                    window.close();
                    this.setPruefstatus(id, pruefstatus, anmerkung, fileName);
                },
                cancel: () => {
                    window.close();
                }
            } as any;
            const windowOptions: AppKitchen.UIHelper.Windows.WindowOptions = {
                buttons: [],
                modal: true,
                easyClose: false,
                width: "40rem",
                height: "30rem",
                closed: () => {}
            };

            window = new AppKitchen.FrameManager.RoutedApp<PruefstatusDialog>(PruefstatusDialog, "").startInWindow(
                windowOptions,
                appOptions);
        }

        private setPruefstatus(id: string, pruefstatus: string, anmerkung: string, fileName: string): void {
            this.model.set({ loading: true });
            AppKitchen.Data.getDataApi('WaermeplanSetPruefstatus',
                { Id: id, Pruefstatus: pruefstatus, Anmerkung: anmerkung, ClearAnmerkung: anmerkung === '' } as Domain.WaermeplanSetPruefstatusRequest,
                success => {
                    if (success) {
                        this.model.dataLoader.loadData();
                        AppKitchen.UIHelper.Notifications.notify(`Der Prüfstatus des Wärmeplan '${fileName}' wurde erfolgreich gesetzt.`, "success", {title: 'Prüfstatus', closeAfter: 5000});
                    }
                    this.model.set({ loading: false });
                }, error => this.model.set({ loading: false }));
        }

        private downloadFile(id: string): void {
            const apiUrl = "./api";

            const request = new XMLHttpRequest();
            request.responseType = "blob";
            request.open("GET", `${apiUrl}/MeldungenFileReader?id=${escape(id)}`);
            request.onload = function () { DeteilansichtMeldungGridView.onLoaded(this); }
            request.send();
        }

        private static onLoaded(xhr: XMLHttpRequest): void {
            if (xhr.status !== 200) {
                AppKitchen.UIHelper.Notifications.notify(Strings.DeteilansichtMeldungen_Download_Fail_Notification_Content,
                    "warning",
                    { title: Strings.DeteilansichtMeldungen_Download_Fail_Notification_Title + ` - Status: ${xhr.status}`, closeAfter: 3000 });
                return;
            }

            const downloadUrl = window.URL.createObjectURL(xhr.response);
            const filename = this.getFileNameFromRequest(xhr);

            if (!downloadUrl || !filename) {
                AppKitchen.UIHelper.Notifications.notify(Strings.DeteilansichtMeldungen_Download_Fail_Notification_Content,
                    "warning",
                    { title: Strings.DeteilansichtMeldungen_Download_Fail_Notification_Title, closeAfter: 3000 });

                return;
            }

            this.createLinkAndClickIt(downloadUrl, filename);

            AppKitchen.UIHelper.Notifications.notify(Utils.StringUtils.format(Strings.DeteilansichtMeldungen_Download_Success_Notification_Content, filename),
                "success",
                { title: Strings.DeteilansichtMeldungen_Download_Success_Notification_Title, closeAfter: 3000 });
        }

        private static createLinkAndClickIt(downloadUrl: string, filename: string): void {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = downloadUrl;
            a.download = filename;
            a.click();
        }

        private static getFileNameFromRequest(xhr: XMLHttpRequest): string {
            let filename = "download-" + $.now();
            const disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            return decodeURI(filename);
        }
    }
}