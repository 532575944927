/// <reference path="KEABaseApp.ts"/>

namespace KEA.BW.Waermeplanung.Web.Apps {

    export class Routes {
        public static readonly Dashboard: string = "";
        public static readonly ZeitreihenWaermeplanung: string = "zeitreihen-waermeplanung";
        public static readonly ImportZeitreihenWaermeplanung: string = "import-zeitreihen-waermeplanung";
        public static readonly Meldeliste: string = "meldeliste";
        public static readonly DeteilansichtMeldungen: string = "detailansicht-meldungen";
        public static readonly SetMeldedaten: string = "set-meldedaten";
        public static readonly UploadWaermeplaene: string = "upload-waermeplaene";
        public static readonly Auswertung: string = "auswertung";
    }

    export class WaermeplanungMainApp extends KEABaseApp {

        getRoutedAppsAndMenu(): [apps: AppKitchen.FrameManager.RoutedApp<any>[],
            menu: AppKitchen.FrameManager.MenuItem] {

            const importZeitreihenWaermeplanung = new AppKitchen.FrameManager.RoutedApp<ImportZeitreihenAppOptions>(Apps.ImportZeitreihenApp, Routes.ImportZeitreihenWaermeplanung,
                {
                    dataLoaderDocumentName: "Waermeplanung",
                    dataLoaderDocumentType: "Import",
                    inventoryId: "Importvorgaenge_Waermeplanung"
                });
            const zeitreihenWaermeplanung = new AppKitchen.FrameManager.RoutedApp<TimeSeriesBaseAppOptions>(Apps.TimeSeriesBaseApp, Routes.ZeitreihenWaermeplanung,
                {
                    headerTitle: Strings.ZeitreihenWaermeplanung_Title,
                    importViewApp: importZeitreihenWaermeplanung,
                    dataRequestEndpoint: "ZeitreihenWaermeplanung",
                    filterRequestEndpoint: "ZeitreihenWaermeplanungTimeseriesFilter",
                    treeViewTreeId: AppKitchen.GlobalSettings.customConfig.CommuneTreeWaermeplanung
                });
            const meldeliste = new AppKitchen.FrameManager.RoutedApp<MeldelisteAppOptions>(Apps.MeldelisteApp, Routes.Meldeliste);
            const uploadWaermeplaene = new AppKitchen.FrameManager.RoutedApp<UploadWaermeplaeneAppOptions>(Apps.UploadWaermeplaeneApp, Routes.UploadWaermeplaene);
            const deteilansichtMeldungen = new AppKitchen.FrameManager.RoutedApp<DeteilansichtMeldungenAppOptions>(Apps.DeteilansichtMeldungenApp, Routes.DeteilansichtMeldungen,
                {
                    uploadWaermeplaeneApp: uploadWaermeplaene
                });

            const dashboard = new AppKitchen.FrameManager.RoutedApp<DashboardAppOptions>(Apps.DashboardApp, Routes.Dashboard,
                {
                    defaultApp: zeitreihenWaermeplanung.route
                });

            const evaluations = new AppKitchen.FrameManager.RoutedApp<EvaluationsAppOptions>(
                Apps.EvaluationsApp, Routes.Auswertung,
                {
                    headerTitle: Strings.Evaluations_Title,
                    importViewApp: null,
                    dataRequestEndpoint: "ZeitreihenBerichteWaermeplanung",
                    filterRequestEndpoint: "ZeitreihenBerichteWaermeplanungTimeseriesFilter",
                    templateFolder: AppKitchen.GlobalSettings.customConfig.TemplateWaermeplanungsAuswertungen,
                    dropDownLabel: Strings.Evaluations_DropdownLabel,
                    treeViewTreeId: AppKitchen.GlobalSettings.customConfig.CommuneTree
                });

            const routedApps = [
                dashboard,
                zeitreihenWaermeplanung,
                importZeitreihenWaermeplanung,
                meldeliste,
                deteilansichtMeldungen,
                uploadWaermeplaene,
                evaluations
            ];

            const menu = new AppKitchen.FrameManager.MenuItem(Strings.Menu_PageTitle, null,
                [
                    //new AppKitchen.FrameManager.MenuItem("Dashboard", { route: dashboard.route, highlightOnNavigate: true }),
                    new AppKitchen.FrameManager.MenuItem(Strings.Menu_ZeitreihenWaermeplanung, { route: zeitreihenWaermeplanung.route, highlightOnNavigate: true }),
                    //new AppKitchen.FrameManager.MenuItem(Strings.Menu_Import, { route: importZeitreihenWaermeplanung.route, highlightOnNavigate: true }),
                    new AppKitchen.FrameManager.MenuItem(Strings.Menu_Meldelisten, { route: meldeliste.route, highlightOnNavigate: true }),
                    new AppKitchen.FrameManager.MenuItem(Strings.Evaluations_PageTitle, { route: evaluations.route, highlightOnNavigate: true }),
                    //new AppKitchen.FrameManager.MenuItem(Strings.Menu_DeteilansichtMeldungen, { route: deteilansichtMeldungen.route, highlightOnNavigate: true })
                ]);

            return [routedApps, menu];
        }

        getTemplate(): string {
            return Templates.WaermeplanungMainApp;
        }

    }
}