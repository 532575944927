module KEA.BW.Waermeplanung.Web.Utils.Grid {


    export const removeSpreadsheetActionBar = (spreadsheet: kendo.ui.Spreadsheet): void => {
        const actionBar = spreadsheet.element.children()[0];
        actionBar.parentNode.removeChild(actionBar);

        spreadsheet.resize();
    }


    export const transformSheet = (gridData: KEA.BW.Waermeplanung.Domain.GridData): kendo.ui.SpreadsheetSheet => {
        const filter = `A1:${getLetterFromIndex(gridData.columnsCount - 1)}${gridData.rowsCount}`;
        return {
            columns: getColumns(gridData),
            filter: {
                ref: filter
            },
            frozenColumns: gridData.frozenColumnsCount,
            frozenRows: gridData.frozenRowsCount,
            rows: transformRows(gridData.rows),     
        };
    }


    export const getLetterFromIndex = (n: number): string => {
        return String.fromCharCode(97 + n);
    }


    export const getColumns = (gridData: KEA.BW.Waermeplanung.Domain.GridData): kendo.ui.SpreadsheetSheetColumn[] => {
        const columns: kendo.ui.SpreadsheetSheetColumn[] = [];


        for (let i = 0; i < gridData.frozenColumnsCount; i++) {
            const tempWidth = i < (gridData.frozenColumnsCount - 1) ? 200 : 70;
            columns.push({
                width: tempWidth
                
            });
        }
        
        return columns;
    }


    export const transformRows = (rows: KEA.BW.Waermeplanung.Domain.GridCell[][]): kendo.ui.SpreadsheetSheetRow[] => {
        return rows.map(d => transformRow(d));
    }


    export const transformRow = (row: KEA.BW.Waermeplanung.Domain.GridCell[]): kendo.ui.SpreadsheetSheetRow => {
        return {
            cells: row.map(d => transformColumn(d))
        }
    }

    export const transformColumn = (cell: KEA.BW.Waermeplanung.Domain.GridCell): kendo.ui.SpreadsheetSheetRowCell => {
        let rowCell: kendo.ui.SpreadsheetSheetRowCell = {
            value: cell.value,
            background: "#ffffff",
            color: "#000000",
            enable: false
        };
        if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.Header) {
            rowCell.background = "#dddad6";
            rowCell.bold = true;
            rowCell.textAlign = "left";
        } else if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.Attribute) {
            rowCell.background = "#eeedeb";
        } else if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.Number) {
            const countDecimals = Web.Utils.Number.countDecimals(cell.value);
            if (countDecimals > 0) {
                rowCell.format = "#,#.#############";
            } else {
                rowCell.format = "#,#";
            }
        } else if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.SzenarioIst) {
            rowCell.background = "#eeedeb";
        } else if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.SzenarioKlima) {
            rowCell.background = "#e2efda";
        } else if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.SzenarioKlimaPlus) {
            rowCell.background = "#c6e0b4";
        } else if (cell.cellType === KEA.BW.Waermeplanung.Domain.GridCellType.SzenarioRef) {
            rowCell.background = "#66ccff";
        }
        return rowCell;
    }

}