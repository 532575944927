module KEA.BW.Waermeplanung.Web.Controls {

    export interface UploadWaermeplaeneModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class UploadWaermeplaeneModel extends AppKitchen.ModelBase<UploadWaermeplaeneModelAttributes> {

    }

    export interface UploadWaermeplaeneViewOptions extends AppKitchen.ViewBaseOptions {
        close: () => void;
    }

    export class Wärmeplan {
        constructor(
            public readonly id: string,
            public readonly meldelisteId: string,
            public readonly documentTyp: string,
            public readonly documentName: string,
            public readonly documentSize: number,
            public readonly status: string,
        ) { }
    }

    export class UploadWaermeplaeneView extends AppKitchen.ViewBase<UploadWaermeplaeneModel> {
        public options: UploadWaermeplaeneViewOptions;
        private parameters: DeteilansichtMeldungenRoutingParameters;
        private userId: string;

        private dataLoader: AppKitchen.Data.EventDataLoader;

        private uploadWaermeplanModel1: UploadWaermeplanModel;
        private uploadWaermeplanModel2: UploadWaermeplanModel;
        private uploadWaermeplanModel3: UploadWaermeplanModel;
        private uploadWaermeplanModel4: UploadWaermeplanModel;

        private container: {
            uploadWaermeplan1: HTMLElement,
            uploadWaermeplan2: HTMLElement,
            uploadWaermeplan3: HTMLElement,
            uploadWaermeplan4: HTMLElement,
        }

        private buttons: {
            uploadAll: JQuery,
            close: JQuery,
        };

        private inputs: {
        };

        constructor(model: UploadWaermeplaeneModel, targetContainer: HTMLElement, options?: UploadWaermeplaeneViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, { close: null}));

            this.setTemplate(Web.Templates.UploadWaermeplaene);

            this.render();
        }

        public render() {
            this.renderTemplate({});
            this.setContainers();
            this.setInputs();
            this.setButtons();
            this.setEvents();
            this.getParameters();
            this.initDataLoader();
            this.initUploadWaermeplanControls();
            return this;
        }

        private setContainers(): void {
            this.container = {
                uploadWaermeplan1: this.$el.find(".content-1")[0],
                uploadWaermeplan2: this.$el.find(".content-2")[0],
                uploadWaermeplan3: this.$el.find(".content-3")[0],
                uploadWaermeplan4: this.$el.find(".content-4")[0],
            }
        }

        private setButtons(): void {
            this.buttons = {
                uploadAll: this.$el.find('.upload-all-button'),
                close: this.$el.find('.close-button'),
            }
        }

        private setInputs(): void {
        }

        private setEvents(): void {
            //this.buttons.uploadAll.click(() => this.uploadAll());
            this.buttons.uploadAll.remove();
            this.buttons.close.click(() => this.options.close());
        }

        private getParameters(): void {
            this.parameters = DeteilansichtMeldungenRoutingService.getParameters();
            if (this.parameters === null || this.parameters.meldelisteId === null) {
                console.error(`No parameters where set!`);
            }

            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();
            this.userId = applicationInfo.UserId;
        }

        private initDataLoader(): void {
            if (this.parameters === null || this.parameters.meldelisteId === null) {
                return;
            }
            this.model.set({ loading: true });

            const config: AppKitchen.Data.GridConfig = JSON.parse(Data.getDocumentSync("Waermeplaene", "DeteilansichtMeldungen"));

            config.EventItemRestrictions = [{
                ItemId: "Id",
                InventoryId: "Meldeliste",
                Operation: "AND",
                Value: this.parameters.meldelisteId
            }];

            this.dataLoader = new AppKitchen.Data.EventDataLoader(config, () => {

                this.dataLoader.loadData({
                    success: () => {
                        this.model.set({ loading: false });
                    }
                });
            });
        }

        private initUploadWaermeplanControls(): void {

            this.uploadWaermeplanModel1 = new UploadWaermeplanModel(this.dataLoader,
                {
                    documentId: 1
                });
            // ReSharper disable once WrongExpressionStatement
            new UploadWaermeplanView(this.uploadWaermeplanModel1, this.container.uploadWaermeplan1);

            this.uploadWaermeplanModel2 = new UploadWaermeplanModel(this.dataLoader,
                {
                    documentId: 2
                });
            // ReSharper disable once WrongExpressionStatement
            new UploadWaermeplanView(this.uploadWaermeplanModel2, this.container.uploadWaermeplan2);

            this.uploadWaermeplanModel3 = new UploadWaermeplanModel(this.dataLoader,
                {
                    documentId: 3
                });
            // ReSharper disable once WrongExpressionStatement
            new UploadWaermeplanView(this.uploadWaermeplanModel3, this.container.uploadWaermeplan3);

            this.uploadWaermeplanModel4 = new UploadWaermeplanModel(this.dataLoader,
                {
                    documentId: 4
                });
            // ReSharper disable once WrongExpressionStatement
            new UploadWaermeplanView(this.uploadWaermeplanModel4, this.container.uploadWaermeplan4);

        }

        private uploadAll(): void {
            this.uploadWaermeplanModel1.sendFile(() => {
                this.uploadWaermeplanModel2.sendFile(() => {
                    this.uploadWaermeplanModel3.sendFile(() => {
                        this.uploadWaermeplanModel4.sendFile();
                    });
                });
            });
        }
    }
}