module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;
    import Api = AppKitchen.Api;

    export interface BICO2ModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class BICO2Model extends AppKitchen.ModelBase<BICO2ModelAttributes> {
    }

    export interface BICO2ViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class BICO2View extends AppKitchen.ViewBase<BICO2Model> {
        public options: BICO2ViewOptions;
        public requestCall: JQueryXHR;

        private container: {
            configuration: HTMLElement,
            bico2_container: HTMLElement,
            bico2_bilanzierungstool_container: HTMLElement
        }

        private buttons: {
            uploadGrunddaten: JQuery,
            generateSteckbrief: JQuery,
            downloadExcel: JQuery,
            downloadPDF: JQuery
        };

        private commune: string = "";

        constructor(model: BICO2Model, targetContainer: HTMLElement, options?: BICO2ViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            this.setTemplate(Web.Templates.BICO2);
            this.render();
            this.checkDownload();
        }

        public render() {
            this.renderTemplate({});
            this.setContainers();
            this.setButtons();
            this.setEvents();
            this.loadCommunes();

            return this;
        }

        private checkDownload(): void {
            const parameters = NutzungsdatenRoutingService.getParameters();
            if (KEA.BW.Waermeplanung.Web.Utils.File.checkDownload(parameters)) {
                BICO2RoutingService.routeTo(new BICO2RoutingParameters());
            }
        }    

        private setContainers(): void {
            this.container = {
                configuration: this.$el.find(".configuration")[0],
                bico2_container: this.$el.find(".bico2-container")[0],
                bico2_bilanzierungstool_container: this.$el.find(".bico2-bilanzierungstool-container")[0],
            }
        }

        private setButtons(): void {
            this.buttons = {
                uploadGrunddaten: this.$el.find(".download-grunddaten-button"),
                generateSteckbrief: this.$el.find(".generate-steckbrief-button"),
                downloadExcel: this.$el.find(".download-excel-button"),
                downloadPDF: this.$el.find(".download-pdf-button"),
            }
        }

        private setEvents(): void {
            this.buttons.uploadGrunddaten.click(() => {
                NutzungsdatenRoutingService.routeTo(
                    new NutzungsdatenRoutingParameters(
                        this.commune,
                        Apps.BICO2Routes.statistischeGrunddaten,
                        "",
                        Strings.Nutzungsdaten_Typ_Statistische_Grunddaten)
                );
            });

            this.buttons.generateSteckbrief.click(() => {
                NutzungsdatenRoutingService.routeTo(
                    new NutzungsdatenRoutingParameters(
                        this.commune,
                        Apps.BICO2Routes.kommunenSteckbrief,
                        "",
                        Strings.Nutzungsdaten_Typ_Kommunensteckbrief)
                );
            });

            this.buttons.downloadExcel.click(() => {
                NutzungsdatenRoutingService.routeTo(
                    new NutzungsdatenRoutingParameters(
                        "",
                        Apps.BICO2Routes.bico2,
                        AppKitchen.GlobalSettings.customConfig.BICO2BWToolPath,
                        Strings.Nutzungsdaten_Typ_BICO2BW_Tool)
                );
            });

            this.buttons.downloadPDF.click(() => {
                NutzungsdatenRoutingService.routeTo(
                    new NutzungsdatenRoutingParameters(
                        "",
                        Apps.BICO2Routes.bico2,
                        AppKitchen.GlobalSettings.customConfig.BICO2BWToolAnleitungPath,
                        Strings.Nutzungsdaten_Typ_BICO2BW_Tool_Anleitung)
                );
            });

        }

        private loadCommunes(): void {

            this.model.set({ loading: true });

            if (this.requestCall) {
                this.requestCall.abort();
            }

            this.requestCall = AppKitchen.Data.getData("Bico2CommuneList", "",
                (response: Array<KEA.BW.Waermeplanung.Domain.Commune> ) => {
                    //AppKitchen.UIHelper.removeLoader(this.container.grid[0]);
                    //this.createSpreadsheet(response);
                    this.model.set({ loading: false });

                    const communeNames = new Array<string>();
                    for (let entry of response) {
                        communeNames.push(entry.Name);
                    }

                    this.createConfigurationForm(communeNames);
                },
                () => {
                    this.model.set({ loading: false });
                    console.log("Failed to load: 'CommuneList'");
                    //AppKitchen.UIHelper.removeLoader(this.container.grid[0]);
                });
        }

        private convertAll(treeViewSubData: Api.Models.TreeViewData[], idPrefix: string = null): AppKitchen.Controls.TreeView.TreeViewItem<Api.Models.TreeViewData>[] {
            return treeViewSubData.AsLinq<Api.Models.TreeViewData>().Select((i) => this.convert(i, idPrefix)).ToArray();
        }


        private convert(treeViewData: Api.Models.TreeViewData, idPrefix: string = null): AppKitchen.Controls.TreeView.TreeViewItem<Api.Models.TreeViewData> {
            const id = idPrefix ? `${idPrefix}_${treeViewData.descriptorId}` : treeViewData.descriptorId;
            return {
                data: treeViewData,
                text: treeViewData.descriptorName,
                imageUrl: treeViewData.dimensionIconData,
                expanded: true,
                items: this.convertAll(treeViewData.items, id),
                id: id
            }
        }

        private createConfigurationForm(values: Array<string>): void {
            const pool: AppKitchen.Controls.Forms.TextPool =
            {
                values: values,
                restrict: true,
                search: true
            }

            const fieldModel = new AppKitchen.Controls.Forms.FieldModel("commune", AppKitchen.Controls.Forms.FieldType.Text, null, {
                pool: pool,
                mandatory: false,
                kendoOptions: {
                    placeholder: Strings.BICO2_Kommune_Placeholder,
                    filter: "contains",
                    minLength: 1,
                    enforceMinLength: false,
                },
            });

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.TextFieldView(fieldModel, { editable: true });

            const formModel = new AppKitchen.Controls.Forms.FormModel([fieldModel], {});

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.HorizontalTabularFormView(formModel, this.container.configuration, { editable: true });
                        
            formModel.onAnyValueChange((fieldModel, value) => {
                this.commune = value;
                localStorage.setItem("bico2-commune", value);
            });

            const storedCommune = localStorage.getItem("bico2-commune");
            if (storedCommune !== null) {
                formModel.setFieldsData({ commune: storedCommune });
                this.commune = storedCommune;
            } else {
                formModel.setFieldsData({ commune: pool.values[0] });
                this.commune = pool.values[0];
            }

            

        }


    }
}