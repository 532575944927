module KEA.BW.Waermeplanung.Web.FunicularSwitch {

    export abstract class Option<T> {

        public static some<T1>(value: T1): Option<T1> {
            return new Some(value);
        }

        public static none<T1>(): Option<T1> {
            return new None();
        }

        private readonly _tag;

        constructor(_tag) {
            this._tag = _tag;
        }

        public isSome(): boolean {
            return this._tag === Some.Tag;
        }

        public isNone(): boolean {
            return this._tag === None.Tag;
        }

        public map<T1>(some: (value: T) => T1, none: () => T1): T1 {
            return this.isSome() ? some((<any>this).value) : none();
        }

        public match(some: (value: T) => void, none: () => void): void {
            this.isSome() ? some((<any>this).value) : none();
        }
    }

    class Some<T> extends Option<T> {
        public static Tag = 'Some';
        public readonly value: T;

        constructor(value: T) {
            super(Some.Tag);
            this.value = value;
        }
    }

    class None<T> extends Option<T> {
        public static Tag = 'None';

        constructor() {
            super(None.Tag);
        }
    }
}