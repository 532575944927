module KEA.BW.Waermeplanung.Web.Utils.File {

    export const getFileName = (file: File): string => {
        return file ? file.name : undefined;
    }

    export const getGeneratedFileNamePart = (): string => {
        return moment().format('YYYYMMDDHHmmss');
    }

    export const getFileNameWithoutExtension = (file: File): string => {
        return getFileName(file).replace(/\.[^/.]+$/, "");
    }

    export const getFileExtension = (file: File): string => {
        return getFileExtensionFromName(file.name);
    }

    export const getFileExtensionFromName = (fileName: string): string => {
        const re = /(?:\.([^.]+))?$/;

        return fileName ? re.exec(fileName)[1] : undefined;
    }

    export const acceptFile = (file: File, acceptedExtensions: string[]): boolean => {
        acceptedExtensions = acceptedExtensions.map(e => e.toLocaleLowerCase());
        const extension = getFileExtension(file).toLocaleLowerCase();

        return acceptedExtensions.some(e => e === extension);
    }

    /**
     * Format bytes as human-readable text.
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     *
     * @return Formatted string.
     */
    export const fileSizeToString = (bytes, si = false, dp = 1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    // add a hidden button for downloading, prevents new tab warning
    export const download = (filename, text): void => {
        const newLink = document.createElement('a') as unknown as HTMLLinkElement;
        newLink.href = filename;
        newLink.target = "_blank";
        newLink.click();
    }

    export const checkDownload = (parameters): boolean => {
        // check if we need to downlod a file
        if (parameters.downloadPath) {
            download(parameters.downloadPath, "");
            return true;
        }
        return false;
    }

}