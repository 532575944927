namespace KEA.BW.Waermeplanung.Web.Apps {

    export abstract class KEABaseApp extends AppKitchen.AppBase<AppKitchen.AppBaseOptions> {

        start() {
            Utils.Localization.TranslateSpreadsheetToGerman();
            this.startUserInfoService();
        }

        protected startUserInfoService() {
            Services.ApplicationInfoService.instance.init((applicationInfo) => {
                Services.UserInfoService.instance.init(() => {
                    const app = this.startApp()
                    this.ApplyApplicationInfo(applicationInfo, app);
                });
            });
        }

        abstract getRoutedAppsAndMenu(): [apps: AppKitchen.FrameManager.RoutedApp<any>[],
            menu: AppKitchen.FrameManager.MenuItem];

        abstract getTemplate(): string;

        protected startApp(): AppKitchen.FrameManager.MainApp {

            const appsMenu = this.getRoutedAppsAndMenu();

            const userMenu = this.options.requiresLogin ? {
                userMenuStyle: AppKitchen.FrameManager.UserMenuStyle.Extended,
                extendedUserInfo: false
            } : {
                userMenuStyle: AppKitchen.FrameManager.UserMenuStyle.Hidden
            };

            // ReSharper disable once WrongExpressionStatement
            const app = new AppKitchen.FrameManager.MainApp(this.el,
                {
                    apps: appsMenu[0],
                    mainMenu: appsMenu[1],
                    template: this.getTemplate(),
                    userMenu: userMenu,
                    helpMenu: {
                        customItems: [
                        ]
                    }
                });

            // set base link of the logo
            (this.$el.find(".a-header-title")[0] as HTMLLinkElement).href =
                AppKitchen.GlobalSettings.customConfig.KeaBwHomeURL;

            return app;
        }

        protected ApplyApplicationInfo(applicationInfo: KEA.BW.Waermeplanung.Domain.ApplicationInfo,
            app: AppKitchen.FrameManager.MainApp) {

            let targetContainer = app.$el.find(".a-header-menu")[0];
            const regionContainer = app.$el.find(".a-header-region");
            const regionLabelElement = app.$el.find(".region-label");
            if (applicationInfo.IsKommune) {
                regionLabelElement.text(applicationInfo.Commune.Name);

                regionLabelElement.kendoTooltip({
                    content: () => Utils.StringUtils.format(Strings.MenuTitle_Region_Tooltip, applicationInfo.Commune.Name),
                    position: "bottom",
                });

            } else {
                regionContainer.remove();
            }

            if (this.options.requiresLogin) {
                // change the logout behavior of the extended user menu to not redirect back to the oauth
                this.$el.find(".a-user-btn-logout").click((e) => Utils.Logout.logout(e, this.$el));
            }

            // fix the username, default AppKitchen sometimes replaces Umlauts
            $("[data-role=tooltip]").each((i, el) => {
                var tooltip = $(el).data("kendoTooltip");
                tooltip.options.content = applicationInfo.UserName;
            });
        }
    }
}