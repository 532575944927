module KEA.BW.Waermeplanung.Web.Services{
    export type SerializedParameterSet = { [key: string]: string };

    export class ParametersBase {

        public getParameterSet(): SerializedParameterSet {
            const param: SerializedParameterSet = {};
            return param;
        }

        public buildRoute(basePath: string): string {
            const parameterSet = this.getParameterSet();
            const parameter = Object.keys(parameterSet)
                .filter(v => parameterSet[v] !== null && parameterSet[v] !== "" && parameterSet[v] !== "undefined")         // only use parameters which are not empty
                    .map(k => `${k}=${parameterSet[k]}`)
                .join('&');

            if (basePath) {
                if (parameter) {
                    return `#/${basePath}/?${parameter}`;
                } else {
                    return `#/${basePath}`;
                }
            } else {
                // base path is undefined, just route back to main
                if (parameter) {
                    return `?${parameter}`;
                } else {
                    return "";
                }
            }
        }
    }


    export class RoutingService {
        protected  static readParameters(): { [key: string]: string } {
            return AppKitchen.BrowserHelper.UrlQuery.getParameters();
        }

        protected static writeParameters(parameters: { [key: string]: string }): void {
            Object.keys(parameters).forEach(k => {
                // only set url parameter if its not empty
                if (parameters[k])
                {
                    AppKitchen.BrowserHelper.UrlQuery.setParameter(k, parameters[k]);
                }
            });
        }

        public static getRoute(parameters: ParametersBase, path: string): string {
            return parameters.buildRoute(path);
        }

        public static navigate(route: string): void {
            AppKitchen.getRouter().navigate(route, true);
        }

        public static routeTo(parameters: ParametersBase, path: string): void {
            this.navigate(this.getRoute(parameters, path));
        }
    }
    
}