module KEA.BW.Waermeplanung.Web.Apps {

    export interface ImportZeitreihenAppOptions extends AppKitchen.AppBaseOptions {
        dataLoaderDocumentName: string;
        dataLoaderDocumentType: string;
        inventoryId: string;
    }

    export class ImportZeitreihenApp extends AppKitchen.AppBase<ImportZeitreihenAppOptions> {

        private model: Controls.ImportZeitreihenModel;

        start() {
            if (this.options.title) {
                document.title = this.options.title;
            } else {
                document.title = this.options.title = Strings.ImportZeitreihenWaermeplanung_PageTitle;
            }

            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();

            if (!applicationInfo.IsKommune) {
                return;
            }

            this.model = new Controls.ImportZeitreihenModel(
                {
                    userId: applicationInfo.UserId,
                    communeId: applicationInfo.Commune.Id,
                    dataLoaderDocumentName: this.options.dataLoaderDocumentName,
                    dataLoaderDocumentType: this.options.dataLoaderDocumentType,
                    inventoryId: this.options.inventoryId
                });

            // ReSharper disable once WrongExpressionStatement
            new Controls.ImportZeitreihenView(this.model, this.el, {});
        }

        dispose() {
            if (this.model && this.model.dataLoader) {
                this.model.dataLoader.abortRequests();
                this.model.dataLoader.dispose();
            }
        }
    }
}