/// <reference path="KEABaseApp.ts"/>

namespace KEA.BW.Waermeplanung.Web.Apps {

    export class BICO2Routes {
        public static readonly bico2: string = "";
        public static readonly kommunenSteckbrief: string = "kommunen-steckbrief";
        public static readonly nutzungsDaten: string = "nutzer-infos";
        public static readonly statistischeGrunddaten: string = "statistische-grunddaten";

    }

    export class BICO2MainApp extends KEABaseApp {

        // this is a public site, there is no application info
        start() {
            Utils.Localization.TranslateSpreadsheetToGerman();
            this.startApp();
        }

        getRoutedAppsAndMenu(): [apps: AppKitchen.FrameManager.RoutedApp<any>[],
            menu: AppKitchen.FrameManager.MenuItem] {

            const bico2 = new AppKitchen.FrameManager.RoutedApp<BICO2AppOptions>(Apps.BICO2App, BICO2Routes.bico2);
            const kommunenSteckbrief = new AppKitchen.FrameManager.RoutedApp<KommunenSteckbriefAppOptions>(Apps.KommunenSteckbriefApp, BICO2Routes.kommunenSteckbrief);
            const nutzungsDaten = new AppKitchen.FrameManager.RoutedApp<NutzungsdatenAppOptions>(Apps.NutzungsdatenApp, BICO2Routes.nutzungsDaten);
            const statistischeGrunddaten = new AppKitchen.FrameManager.RoutedApp<StatistischeGrunddatenAppOptions>(Apps.StatistischeGrunddatenApp, BICO2Routes.statistischeGrunddaten);

            const routedApps = [
                bico2,
                kommunenSteckbrief,
                nutzungsDaten,
                statistischeGrunddaten
            ];

            const menu = new AppKitchen.FrameManager.MenuItem(Strings.Menu_PageTitle, null,
                []);

            return [routedApps, menu];
        }

        getTemplate(): string {
            return Templates.BICO2MainApp;
        }
    }
}