/// <reference path="../../Services/RoutingService.ts"/>

module KEA.BW.Waermeplanung.Web.Controls {
    import ParametersBase = BW.Waermeplanung.Web.Services.ParametersBase;
    import SerializedParameterSet = BW.Waermeplanung.Web.Services.SerializedParameterSet;
    import RoutingService = BW.Waermeplanung.Web.Services.RoutingService;

    class RoutingParameters {
        public static readonly commune: string = "kommune";
        public static readonly returnUrl: string = "return-url";
        public static readonly downloadPath: string = "download";
        public static readonly usageType: string = "usage-type";
    }

    export class NutzungsdatenRoutingParameters extends ParametersBase {
        constructor(
            public readonly commune: string = "",
            public readonly returnUrl: string,
            public readonly downloadPath: string = "",
            public readonly usageType: string = "",
        ) { super() }

        public getParameterSet(): SerializedParameterSet {
            const param: SerializedParameterSet = {};
            param[RoutingParameters.commune] = encodeURI(this.commune);
            param[RoutingParameters.returnUrl] = encodeURI(this.returnUrl);
            param[RoutingParameters.downloadPath] = encodeURI(this.downloadPath);
            param[RoutingParameters.usageType] = encodeURI(this.usageType);
            return param;
        }
    }

    export class NutzungsdatenRoutingService extends RoutingService{

        public static getParameters(): NutzungsdatenRoutingParameters {
            const urlQuery = this.readParameters();
            return new NutzungsdatenRoutingParameters(
                urlQuery[RoutingParameters.commune],
                urlQuery[RoutingParameters.returnUrl],
                urlQuery[RoutingParameters.downloadPath],
                urlQuery[RoutingParameters.usageType]
            );
        }

        public static setParameters(parameters: NutzungsdatenRoutingParameters) : void {
            const p = {};
            console.log(parameters.returnUrl);
            p[RoutingParameters.commune] = parameters.commune;
            p[RoutingParameters.returnUrl] = parameters.returnUrl;
            p[RoutingParameters.downloadPath] = parameters.downloadPath;
            p[RoutingParameters.usageType] = parameters.usageType;
            this.writeParameters(p);
        }

        public static routeTo(parameters: NutzungsdatenRoutingParameters): void {
            this.navigate(this.getRoute(parameters, Apps.BICO2Routes.nutzungsDaten));
        }
    }
}