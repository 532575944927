namespace KEA.BW.Waermeplanung.Web.Utils.Localization {

    // fix kendo translation, theres no de-DE translation file for the spreadsheet component
    // so we have to create our own one
    // see: https://docs.telerik.com/kendo-ui/globalization/localization#creating-new-localization-files
    // https://docs.telerik.com/kendo-ui/controls/data-management/spreadsheet/globalization/localization

    export const TranslateSpreadsheetToGerman = (): void => {

        (<any>kendo.spreadsheet).messages.filterMenu =
            $.extend(true, (<any>kendo.spreadsheet).messages.filterMenu, {        
                "sortAscending": Strings.KendoSpreadsheet_filterMenu_sortAscending,
                "sortDescending": Strings.KendoSpreadsheet_filterMenu_sortDescending,
                "filterByValue": Strings.KendoSpreadsheet_filterMenu_filterByValue,
                "filterByCondition": Strings.KendoSpreadsheet_filterMenu_filterByCondition,
                "apply": Strings.KendoSpreadsheet_filterMenu_apply,
                "search": Strings.KendoSpreadsheet_filterMenu_search,
                "clear": Strings.KendoSpreadsheet_filterMenu_clear,
                "blanks": Strings.KendoSpreadsheet_filterMenu_blanks,
                "operatorNone": Strings.KendoSpreadsheet_filterMenu_operatorNone,
                "and": Strings.KendoSpreadsheet_filterMenu_and,
                "or": Strings.KendoSpreadsheet_filterMenu_or,
                "operators": {
                    "string": {
                        "contains": Strings.KendoSpreadsheet_filterMenu_operators_string_contains,
                        "doesnotcontain": Strings.KendoSpreadsheet_filterMenu_operators_string_doesnotcontain,
                        "startswith": Strings.KendoSpreadsheet_filterMenu_operators_string_startswith,
                        "endswith": Strings.KendoSpreadsheet_filterMenu_operators_string_endswith,
                        "matches": Strings.KendoSpreadsheet_filterMenu_operators_string_matches,
                        "doesnotmatch": Strings.KendoSpreadsheet_filterMenu_operators_string_doesnotmatch,
                    },
                    "date": {
                        "eq": Strings.KendoSpreadsheet_filterMenu_operators_date_eq,
                        "neq": Strings.KendoSpreadsheet_filterMenu_operators_date_neq,
                        "lt": Strings.KendoSpreadsheet_filterMenu_operators_date_lt,
                        "gt": Strings.KendoSpreadsheet_filterMenu_operators_date_gt,
                    },
                    "number": {
                        "eq": Strings.KendoSpreadsheet_filterMenu_operators_number_eq,
                        "neq": Strings.KendoSpreadsheet_filterMenu_operators_number_neq,
                        "gte": Strings.KendoSpreadsheet_filterMenu_operators_number_gte,
                        "gt": Strings.KendoSpreadsheet_filterMenu_operators_number_gt,
                        "lte": Strings.KendoSpreadsheet_filterMenu_operators_number_lte,
                        "lt": Strings.KendoSpreadsheet_filterMenu_operators_number_lt,
                    }
                }
            });
    }
}