module KEA.BW.Waermeplanung.Web.Apps {

    export interface MeldelisteAppOptions extends AppKitchen.AppBaseOptions {
    }

    export class MeldelisteApp extends AppKitchen.AppBase<MeldelisteAppOptions> {

        start() {
            document.title = this.options.title = Strings.Meldeliste_PageTitle;
            const model = new Controls.MeldelisteModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.MeldelisteView(model, this.el, {});
        }

        dispose() {
        }
    }
}