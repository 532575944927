module KEA.BW.Waermeplanung.Web.Apps {

    export interface DeteilansichtMeldungenAppOptions extends AppKitchen.AppBaseOptions {
        uploadWaermeplaeneApp: AppKitchen.FrameManager.RoutedApp<Apps.UploadWaermeplaeneAppOptions>;
    }

    export class DeteilansichtMeldungenApp extends AppKitchen.AppBase<DeteilansichtMeldungenAppOptions> {

        private model: Controls.DeteilansichtMeldungenModel;

        start() {
            document.title = this.options.title = Strings.DeteilansichtMeldungen_PageTitle;
            this.model = new Controls.DeteilansichtMeldungenModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.DeteilansichtMeldungenView(this.model, this.el,
                {
                    uploadWaermeplaeneApp: this.options.uploadWaermeplaeneApp
                });
        }

        dispose() {
        }
    }
}