module KEA.BW.Waermeplanung.Web.Apps {

    export interface UploadWaermeplaeneAppOptions extends AppKitchen.AppBaseOptions {
        close: () => void;
    }

    export class UploadWaermeplaeneApp extends AppKitchen.AppBase<UploadWaermeplaeneAppOptions> {

        start() {
            document.title = this.options.title = Strings.UploadWaermeplaene_PageTitle;
            const model = new Controls.UploadWaermeplaeneModel();

            // ReSharper disable once WrongExpressionStatement
            new Controls.UploadWaermeplaeneView(model, this.el, {close: this.options.close});
        }

        dispose() {
        }
    }
}