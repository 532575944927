/// <reference path="../TimeSeriesBase/TimeSeriesBase.ts"/>
module KEA.BW.Waermeplanung.Web.Controls {
    import Apps = KEA.BW.Waermeplanung.Web.Apps;

    export interface EvaluationsModelAttributes extends AppKitchen.ModelBaseAttributes {
    }

    export class EvaluationsModel extends AppKitchen.ModelBase<EvaluationsModelAttributes> {
    }

    export interface EvaluationsViewOptions extends TimeSeriesBaseViewOptions {
        templateFolder: string;
        dropdownLabel: string;
        downloadButtonLabel?: string;
    }

    export class EvaluationsView extends TimeSeriesBaseView {

        protected actionButtons: {
            download: JQuery,
            export: JQuery
        }
        
        private directoryFileInfo: Waermeplanung.Domain.DirectoryFileInfo;
        private selectedTemplate: string;
        private downloadButtonLabel: string;
        private downloading: boolean = false;

        constructor(model: TimeSeriesBaseModel, targetContainer: HTMLElement, options?: EvaluationsViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {
                headerTitle: Strings.TimeSeriesBase_Title,
                importViewApp: null,
                dataRequestEndpoint: "",
                filterRequestEndpoint: "",
                excelFileName: "",
                templateFolder: "",
                dropdownLabel: Strings.Evaluations_DropdownLabel,
                treeViewTreeId: options.treeViewTreeId
            }));

            this.downloadButtonLabel = options.downloadButtonLabel;

            this.setActionButtons();
        }

        protected postRender() {
            this.showTreeView = false;
        }

        private setActionButtons(): void {
            this.actionButtons = {
                download: this.$el.find(".a-button-download"),
                export: this.$el.find(".a-button-export")
            }

            if (this.downloadButtonLabel) {
                this.actionButtons.download[0].innerHTML = this.downloadButtonLabel;
            }
        }
   
        protected setHeaderTitle(): void {
            super.setHeaderTitle();
            AppKitchen.UIHelper.renderTemplateTo(this.container.actionContainer, Templates.Evaluations);
        }
        
        protected setTemplateDownloadEvent(): void {
            this.actionButtons.download[0].removeAttribute("disabled");

            this.actionButtons.download.dblclick((e) => {
                e.preventDefault();
            });

            let that = this;
            this.actionButtons.download.click((e) => {

                if (that.downloading) {
                    return;
                }

                that.downloading = true;
                setTimeout(() => {
                    that.downloading = false;
                }, 1000);

                const newLink = document.createElement('a') as unknown as HTMLLinkElement;
                newLink.href = `api/${(this.options as EvaluationsViewOptions).templateFolder}/${this.selectedTemplate}`;
                newLink.target = "_blank";
                newLink.click();
            });
        }

        protected unsetTemplateDownloadEvent(): void {
            this.actionButtons.download.prop("onclick", null).off("click");
            this.actionButtons.download[0].setAttribute("disabled", "disabled");
        }

        protected onTimeseriesViewListLoaded(response: KEA.BW.Waermeplanung.Domain.TimeseriesViewReference[]): void {
            this.model.set({ loading: false });
            this.getFiles(response);
        }

        private getFiles(timeseriesViewReferences: KEA.BW.Waermeplanung.Domain.TimeseriesViewReference[]): void {
            const requestData: Waermeplanung.Domain.DirectoryFileInfoRequest = {
                DirectoryPath: (this.options as EvaluationsViewOptions).templateFolder
            };
            AppKitchen.Data.getDataApi("DirectoryFileList", requestData,
                (result: Waermeplanung.Domain.DirectoryFileInfo) => {
                    this.directoryFileInfo = result;
                    this.model.set({ loading: false });
                    this.createForm(timeseriesViewReferences);
                });
        }

        // TODO: selected filter is the value, excel files are named after the key (longname) -> fix this somehow
        private setSuitableFile(selectedFilter: string) {
            let found = false;
            const filterPrefix = selectedFilter.split(":")[0];
            for (const fileName of this.directoryFileInfo.FileNames) {
                if (fileName.includes(filterPrefix)) {
                    found = true;
                    this.setTemplateDownloadEvent();
                    this.selectedTemplate = fileName;
                    break;
                }
            }

            if (!found) {
                this.unsetTemplateDownloadEvent();
                this.selectedTemplate = Strings.Evaluations_FileNotFound;
            }
        }

        protected createForm(timeseriesViewReferences: KEA.BW.Waermeplanung.Domain.TimeseriesViewReference[]): void {
            const pool: AppKitchen.Controls.Forms.TextPool = {
                values: timeseriesViewReferences,
                textField: "Name",
                valueField: "Id",
                restrict: true,
                search: true
            }
            const filterFieldModel = new AppKitchen.Controls.Forms.FieldModel(
                "filter",
                AppKitchen.Controls.Forms.FieldType.Text,
                (this.options as EvaluationsViewOptions).dropdownLabel,
                {
                    pool: pool,
                    mandatory: true,
                    kendoOptions: {
                        autoWidth: true,
                        filter: "contains"
                    }
                }
            );

            const labelFieldModel = new AppKitchen.Controls.Forms.FieldModel("label",
                AppKitchen.Controls.Forms.FieldType.Text,
                Strings.Evaluations_Template_Label,
                {
                    //disabled: true,
                    default: Strings.Evaluations_Template_Label,
                    readOnly: true
                }

            );

            // ReSharper disable twice WrongExpressionStatement
            new AppKitchen.Controls.Forms.TextFieldView(filterFieldModel, { editable: true });
            new AppKitchen.Controls.Forms.TextFieldView(labelFieldModel, { editable: false });

            const formModel = new AppKitchen.Controls.Forms.FormModel([filterFieldModel, labelFieldModel], {});

            // ReSharper disable once WrongExpressionStatement
            new AppKitchen.Controls.Forms.HorizontalTabularFormView(formModel, this.container.form, { editable: true });

            formModel.onAnyValueChange((fieldModel, value) => {
                if (fieldModel.attributes.key === "filter") {
                    const displayValue = fieldModel.get().displayValue;
                    this.setSuitableFile(displayValue);
                    labelFieldModel.set({ value: this.selectedTemplate, hasData: true });
                    TimeSeriesBaseRoutingService.setParameters(new TimeSeriesBaseRoutingParameters(value));
                    this.options.excelFileName = displayValue;
                    this.loadSpreadsheet(value);
                }
            });

            const parameters = TimeSeriesBaseRoutingService.getParameters();

            const filterValue = parameters != null && parameters.timeseriesViewId
                ? parameters.timeseriesViewId
                : timeseriesViewReferences[0].Id
                ;

            let labelValue = Strings.Evaluations_Template_Label;

            // finde the name for the id, the dropdown and url paramter is based on the id, but the template needs the long name
            for (const timeseriesViewReference of timeseriesViewReferences) {
                if (timeseriesViewReference.Id === filterValue) {
                    labelValue = timeseriesViewReference.Name;
                    break;
                }
            }

            this.setSuitableFile(labelValue);
            formModel.setFieldsData({
                filter: filterValue,
                label: this.selectedTemplate
            });
        }

        protected createSpreadsheet(gridData: KEA.BW.Waermeplanung.Domain.GridData): kendo.ui.Spreadsheet {
            const spreadSheet = super.createSpreadsheet(gridData);
            // remove onclick first or the button can cause multiple downloads of different views at the same time
            this.actionButtons.export.prop("onclick", null).off("click");
            this.actionButtons.export.click(() => spreadSheet.saveAsExcel());
            return spreadSheet;
        }

    }
}