module KEA.BW.Waermeplanung.Web.Controls {

    export interface ImportZeitreihenModelAttributes extends AppKitchen.ModelBaseAttributes {
        file?: File;
        fileNameOriginal?: string;
        fileNameGenerated?: string;
        fileNameWithoutExtension?: string;
        fileExtension?: string;
        generated?: string;
        uploadProgress?: number;
        userId: string;
        communeId: string;
        dataLoaderDocumentName: string;
        dataLoaderDocumentType: string;
        inventoryId: string;
    }

    export class ImportZeitreihenModel extends AppKitchen.ModelBase<ImportZeitreihenModelAttributes> {

        public dataLoader: ImportZeitreihenDataLoader;

        public successUploadCallback: () => void;
        public failUploadCallback: () => void;

        public readonly userId: string;
        public readonly communeId: string;

        constructor(attributes: ImportZeitreihenModelAttributes) {
            super(attributes);

            const config: AppKitchen.Data.GridConfig = JSON.parse(Data.getDocumentSync(attributes.dataLoaderDocumentName, attributes.dataLoaderDocumentType));

            config.EventItemRestrictions = [{
                ItemId: "NavObject_Reg",
                InventoryId: attributes.inventoryId,
                Operation: "AND",
                Value: attributes.communeId
            }];

            this.dataLoader = new ImportZeitreihenDataLoader(config, () => {
                this.dataLoader.inventoryId = attributes.inventoryId;
                this.dataLoader.loadData();
            });

            const applicationInfo = Services.ApplicationInfoService.instance.getApplicationInfo();

            this.userId = applicationInfo.UserId;
            if (applicationInfo.IsKommune) {
                this.communeId = applicationInfo.Commune.Id;
            }
        }

        public updateFile(file: File): void {
            const fileNameWithoutExtension = Utils.File.getFileNameWithoutExtension(file);
            const fileExtension = Utils.File.getFileExtension(file);
            const generated = Utils.File.getGeneratedFileNamePart();

            this.set(AppKitchen.OptionsHelper.merge({
                file: file,
                fileNameOriginal: Utils.File.getFileName(file),
                fileNameGenerated: `${generated}_${this.communeId}$${fileNameWithoutExtension}.${fileExtension}`,
                fileNameWithoutExtension: fileNameWithoutExtension,
                fileExtension: fileExtension,
                generated: generated,
            }, this.attributes));
        }

        public reset(): void {
            this.set(AppKitchen.OptionsHelper.merge({
                file: null,
                fileNameOriginal: null,
                fileNameGenerated: null,
                fileNameWithoutExtension: null,
                fileExtension: null,
                generated: null,
            }, this.attributes));
        }

        public reload(): void {
            this.dataLoader.loadData();
        }

        public uploadFile(): void {
            this.sendFile();
        }

        public sendFile(finished?: () => void): void {
            if (!this.get().fileNameGenerated || !this.get().fileNameOriginal || !this.communeId) {
                if (finished) finished();
                return;
            }

            const apiUrl = "./api";
            $.ajax({
                dataType: "json",
                url: `${apiUrl}/ZeitreihenFileUpload` +
                    `?fileName=${encodeURI(this.get().fileNameGenerated)}` +
                    `&originalFileName=${encodeURI(this.get().fileNameOriginal)}` +
                    `&commune=${encodeURI(this.communeId)}` +
                    `&eventInventory=${encodeURI(this.attributes.inventoryId)}`,
                type: 'POST',
                xhr: () => {
                    const myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', e => this.uploadProgressHandling(e), false);
                    }
                    return myXhr;
                },
                success: (result) => {
                    if (result) {
                        this.dataLoader.loadData();
                        this.reset();
                        this.set({ isAlreadyUploaded: true });
                        if (this.successUploadCallback) {
                            this.successUploadCallback();
                        }
                    } else {
                        if (this.failUploadCallback) {
                            this.failUploadCallback();
                        }
                    }
                    if (finished) finished();
                },
                error: () => {
                    if (this.failUploadCallback) {
                        this.failUploadCallback();
                    }
                    if (finished) finished();
                },
                data: this.get().file,
                cache: false,
                contentType: false,
                processData: false
            });
        }

        private uploadProgressHandling(e: ProgressEvent<XMLHttpRequestEventTarget>): void {
            if (e.lengthComputable) {
                const s = e.loaded / e.total * 100;
                this.set({
                    uploadProgress: s
                });
            }
        }
    }

    class ImportZeitreihenDataLoader extends AppKitchen.Data.EventDataLoader {

        public inventoryId: string;

        parseDataInternal(data: AppKitchen.Data.DataEntries[]): AppKitchen.Data.DataEntries[] {
            const dict: { [id: string]: any } = {}
            data.forEach((d: any) => {                
                const id = d[`${this.inventoryId}_Id`];
                if (!dict[id]) {
                    dict[id] = d;
                } else {
                    if (dict[id]['Konverter_Dateilog_Verarbeitungszeitpunkt'] < d['Konverter_Dateilog_Verarbeitungszeitpunkt']) {
                        dict[id] = d;
                    }
                }
            });

            return Object.keys(dict).map(key => dict[key]);
        }

        setData(data: AppKitchen.Data.DataEntries[], sender: object): void {
            super.setData(this.parseDataInternal(data), sender);
        }
    }

    export interface ImportZeitreihenViewOptions extends AppKitchen.ViewBaseOptions {
    }

    export class ImportZeitreihenView extends AppKitchen.ViewBase<ImportZeitreihenModel> {
        public options: ImportZeitreihenViewOptions;

        private acceptedFileTypes: string[] = ["xlsx", "xlsm", "xls"];

        private gridModel: AppKitchen.Controls.Grids.GridModel;
        private gridView: AppKitchen.Controls.Grids.GridView;

        private infoNotification: AppKitchen.Controls.Popups.Notification;
        private uploadFileTooltip: kendo.ui.Tooltip;
        private uploadButtonEnabled: boolean;
        private currentlyUploading: boolean;

        private container: {
            dataFilePickerContent: HTMLElement,
            importList: HTMLElement,
        }

        private buttons: {
            uploadFile: JQuery,
            refreshOverview: JQuery,
        };

        private inputs: {
            dataFile: JQuery,
        };

        constructor(model: ImportZeitreihenModel, targetContainer: HTMLElement, options?: ImportZeitreihenViewOptions) {
            super(model, targetContainer, AppKitchen.OptionsHelper.merge(options, {}));

            this.setTemplate(Web.Templates.ImportZeitreihen);

            this.render();
            this.currentlyUploading = false;
        }

        public render() {
            this.renderTemplate({
                accept: this.acceptedFileTypes.map(s => `.${s}`).join(',')
            });
            this.setContainers();
            this.setInputs();
            this.setButtons();
            this.setEvents();
            this.init();
            return this;
        }

        private init(): void {
            AppKitchen.UIHelper.renderTemplateTo(this.container.dataFilePickerContent,
                Templates.DataFileItemEmpty);

            this.enableUploadFileButton(false);
        }

        private setContainers(): void {
            this.container = {
                dataFilePickerContent: this.$el.find(".data-file-picker-content")[0],
                importList: this.$el.find(".import-list-container")[0]
            }
        }

        private setButtons(): void {
            this.buttons = {
                uploadFile: this.$el.find(".upload-file-button"),
                refreshOverview: this.$el.find(".refresh-overview-button"),
            }
        }

        private setInputs(): void {
            this.inputs = {
                dataFile: this.$el.find(".data-file-picker"),
            };
        }

        private setEvents(): void {
            this.inputs.dataFile.change(e => this.onTradesFileChanged(e));

            this.model.bind('change', () => this.onModelChanged());

            this.buttons.uploadFile.click(() => {
                if (this.uploadButtonEnabled) {
                    this.uploadFile();
                }
            });

            this.buttons.refreshOverview.click(() => this.model.reload());

            this.$el.find(".data-file-picker-label")
                .on("dragenter", (e) => this.onDragEnter(e))
                .on("dragover", (e) => this.onDragOver(e))
                .on("dragleave", (e) => this.onDragLeave(e))
                .on("drop", (e) => this.onDrop(e));

            this.model.successUploadCallback = this.successUpload.bind(this);
            this.model.failUploadCallback = this.uploadFailed.bind(this);

            this.model.bind('change', () => this.setProgress(this.model.get().uploadProgress));

            this.model.dataLoader.onChangeReady().subscribe(() => {
                this.createImportList();
            });
        }

        private onDragEnter(event): void {
            event.preventDefault();

            this.$el.find(".data-file-picker-container").css('background-color', '#deebff');
        }

        private onDragOver(event): void {
            event.preventDefault();
        }

        private onDragLeave(event): void {
            event.preventDefault();

            this.$el.find(".data-file-picker-container").css('background-color', '');
        }

        private onDrop(event): void {
            event.preventDefault();

            this.$el.find(".data-file-picker-container").css('background-color', '');

            const file = event.originalEvent.dataTransfer.files[0];
            this.selectFile(file);
        }

        private onTradesFileChanged(e: JQuery.ChangeEvent): void {
            if (e.target.files.length === 0) {
                this.model.reset();
                return;
            }

            const file = e.target.files[0];
            this.selectFile(file);

            const newInput = $(e.target).clone(true);
            newInput.val("");
            $(e.target).replaceWith(newInput);
        }

        private selectFile(file: File): void {
            if (file && Utils.File.acceptFile(file, this.acceptedFileTypes)) {
                this.model.updateFile(file);
            } else {
                this.notifyWrongFileFormat(file.name);
                this.model.reset();
            }
        }

        private onModelChanged(): void {
             if (this.model.get().fileNameOriginal) {
                AppKitchen.UIHelper.renderTemplateTo(this.container.dataFilePickerContent,
                    Templates.DataFileItem,
                    {
                        fileNameOriginal: this.model.get().fileNameOriginal,
                        fileNameWithoutExtension: this.model.get().fileNameWithoutExtension,
                        fileExtension: this.model.get().fileExtension,
                        generated: this.model.get().generated,
                        commune: this.model.communeId
                    });

                this.$el.find(".remove-button").addClass('active');
                this.$el.find(".remove-button").click((e) => this.removeFile(e));

                this.enableUploadFileButton(true);
            } else {
                AppKitchen.UIHelper.renderTemplateTo(this.container.dataFilePickerContent,
                    Templates.DataFileItemEmpty);

                this.$el.find(".remove-button").removeClass('active');
                this.enableUploadFileButton(false);
            }
        }

        private enableUploadFileButton(isEnable: boolean): void {
            if (this.uploadFileTooltip) {
                this.uploadFileTooltip.destroy();
                this.uploadFileTooltip = null;
            }

            if (this.model.communeId) {
                this.uploadButtonEnabled = isEnable;
                if (isEnable) {
                    this.buttons.uploadFile.removeClass('disabled');
                } else {
                    this.buttons.uploadFile.addClass('disabled');

                    this.uploadFileTooltip = this.buttons.uploadFile.kendoTooltip(
                        {
                            content: () => Strings.ImportZeitreihenWaermeplanung_Upload_Button_Tooltip_NoFile,
                            position: "bottom"
                        }).data("kendoTooltip");
                }
            } else {
                this.uploadButtonEnabled = false;
                this.buttons.uploadFile.addClass('disabled');

                this.uploadFileTooltip = this.buttons.uploadFile.kendoTooltip(
                    {
                        content: () => Strings.ImportZeitreihenWaermeplanung_Upload_Button_Tooltip_NoCummne,
                        position: "bottom"
                    }).data("kendoTooltip");
            }
        }

        private removeFile(event): void {
            event.preventDefault();
            this.model.reset();
        }

        private uploadFile(): void {            
            if (!this.model.get().file) {
                return;
            }

            if (this.currentlyUploading) {
                console.log("Already uploading a file, preventing duplicate upload");
                return;
            }

            // disable upload button until upload is complete
            this.enableUploadFileButton(false);
            this.currentlyUploading = true;

            this.notifyProceedUpload();
            this.model.uploadFile();
        }

        private uploadFailed(): void {
            console.log("Could not upload file.");
            this.setProgress(0);
            this.notifyFailUpload();
            this.enableUploadFileButton(true);
            this.currentlyUploading = false;
        }

        private setProgress(percent: number): void {
            $(".progressbar-progress").width(percent + "%");
        }

        private successUpload(): void {
            this.notifySucceedUpload();
            this.enableUploadFileButton(true);
            this.currentlyUploading = false;
        }

        private notifyProceedUpload(): void {
            this.infoNotification = AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_Progress_Content, this.model.get().fileNameGenerated),
                'info',
                {
                    closeAfter: 30000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_Progress_Title
                });
        }

        private hideProcessNotification(): void {
            if (this.infoNotification) {
                this.infoNotification.close();
            }
        }

        private notifySucceedUpload(): void {
            this.hideProcessNotification();
            AppKitchen.UIHelper.Notifications.notify(
                Strings.ImportZeitreihenWaermeplanung_Notification_Success_Content,
                'success',
                {
                    closeAfter: 10000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_Success_Title
                });
        }

        private notifyFailUpload(): void {
            this.hideProcessNotification();
            AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_Fail_Content, this.model.get().fileNameGenerated),
                'warning',
                {
                    closeAfter: 10000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_Fail_Title
                });
        }

        private notifyNoCommuneSet(): void {
            AppKitchen.UIHelper.Notifications.notify(
                Strings.ImportZeitreihenWaermeplanung_Notification_NoCommune_Content,
                'info',
                {
                    closeAfter: 10000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_NoCommune_Title
                });
        }

        private notifyWrongFileFormat(fileName: string): void {
            AppKitchen.UIHelper.Notifications.notify(
                Utils.StringUtils.format(Strings.ImportZeitreihenWaermeplanung_Notification_WrongFile_Content, fileName, this.acceptedFileTypes.map(s => `.${s}`).join(', ')),
                "warning",
                {
                    closeAfter: 30000,
                    title: Strings.ImportZeitreihenWaermeplanung_Notification_WrongFile_Title,
                });
        }

        private createImportList(): void {
            this.gridModel = new AppKitchen.Controls.Grids.EventGridModel(this.model.dataLoader, {});
            this.gridView = new AppKitchen.Controls.Grids.GridView(this.gridModel, this.container.importList,
                {
                    fillHeight: true,
                    initialSort: { field: `${this.model.attributes.inventoryId}_ZeitpunktBereitstellung`, dir: 'desc' },
                    templates: [{ field: 'Konverter_Dateilog_Status', template: (model) => this.translateStatus(model) }]
                });

            this.gridModel.set({ loading: true });
        }

        private translateStatus(model: any): string {
            const status = model['Konverter_Dateilog_Status'];
            let statusText;
            let isError = false;
            let isSuccess = false;
            let inProgress = false;

            if (status == null) {
                statusText = Strings.ImportZeitreihenWaermeplanung_Status_none;
                inProgress = true;
            } else {
                statusText = Strings[`ImportZeitreihenWaermeplanung_Status_${status}`];

                switch (status) {
                case 0:
                    isError = true;
                    break;
                case 1:
                    isSuccess = true;
                    break;
                case 2:
                    isError = true;
                    break;
                case 3:
                    isError = true;
                    break;
                case 4:
                    isError = true;
                    break;
                case 5:
                    isSuccess = true;
                    break;
                case 6:
                    isError = true;
                    break;
                case 7:
                    isError = true;
                    break;
                case 8:
                    isError = true;
                    break;
                case 9:
                    isError = true;
                    break;
                case 10:
                    isSuccess = true;
                    break;
                case 11:
                    isError = true;
                    break;
                case 12:
                    isSuccess = true;
                    break;
                default:
                }
            }

            return AppKitchen.UIHelper.renderTemplate(Templates.StatusCell,
                {
                    statusText: statusText,
                    isError: isError,
                    isSuccess: isSuccess,
                    inProgress: inProgress
                });
        }
    }
}