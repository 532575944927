module KEA.BW.Waermeplanung.Web.Services {

    export class UserInfoService {

        public static instance: UserInfoService = new UserInfoService();

        private userInfoDictionary: {[id: string]: Domain.UserInfo};

        public init(onReady: () => void ): void {
            AppKitchen.Data.getDataApi("UserInfoList", null, (userInfo: Domain.UserInfo[]) => {
                this.userInfoDictionary = {};
                userInfo.forEach(u => this.userInfoDictionary[u.Id] = u);
                onReady();
            });
        }

        public getUserInfo(id: string): Domain.UserInfo {
            return this.userInfoDictionary[id];
        }

    }
}